/*#region flatpickr (input date)*/
.flatpickr-calendar,
.flatpickr-calendar.multiMonth
  .flatpickr-days
  .dayContainer:nth-child(n + 2)
  .flatpickr-day.inRange:nth-child(7n + 1),
.dayContainer + .dayContainer,
.flatpickr-day.selected.startRange + .endRange:not(:nth-child(7n + 1)),
.flatpickr-day.startRange.startRange + .endRange:not(:nth-child(7n + 1)),
.flatpickr-day.endRange.startRange + .endRange:not(:nth-child(7n + 1)),
.flatpickr-day.inRange,
.flatpickr-day.week.selected,
.flatpickr-weekwrapper .flatpickr-weeks {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.flatpickr-calendar {
  font-family: "Sono", "Roboto", sans-serif;
  font-size: 1rem;
  line-height: 1.4;
  background: var(--button-secondary-color);
  border: 1px solid var(--text-secondary-color);
  border-radius: 0.5rem;
}

.flatpickr-calendar.arrowTop {
  margin-top: 12px;
}

.flatpickr-calendar.arrowBottom {
  margin-top: -12px;
}

.flatpickr-days {
  padding: 0.75rem 0;
}

.flatpickr-calendar.hasTime .flatpickr-time {
  border-top: 1px solid var(--text-secondary-color);
}

.flatpickr-calendar.arrowTop:before {
  border-bottom-color: var(--text-secondary-color);
}
.flatpickr-calendar.arrowTop:after {
  border-bottom-color: var(--button-secondary-color);
}

.flatpickr-calendar.arrowBottom:before {
  border-top-color: var(--text-secondary-color);
}
.flatpickr-calendar.arrowBottom:after {
  border-top-color: var(--button-secondary-color);
}

.flatpickr-months .flatpickr-month {
  background: var(--button-secondary-color);
  color: var(--text-primary-color);
  fill: var(--text-primary-color);
  height: 2.75rem;
  border-radius: 0.5rem 0.5rem 0 0;
}

.flatpickr-months .flatpickr-prev-month,
.flatpickr-months .flatpickr-next-month {
  color: var(--text-primary-color);
  fill: var(--text-primary-color);
}

.flatpickr-months .flatpickr-prev-month:hover,
.flatpickr-months .flatpickr-next-month:hover {
  color: var(--text-secondary-color);
}
.flatpickr-months .flatpickr-prev-month:hover svg,
.flatpickr-months .flatpickr-next-month:hover svg {
  fill: var(--text-secondary-color);
}

.flatpickr-current-month .numInputWrapper span.arrowUp:after {
  border-bottom-color: var(--text-primary-color);
}
.flatpickr-current-month .numInputWrapper span.arrowDown:after {
  border-top-color: var(--text-primary-color);
}

.flatpickr-current-month input.cur-year[disabled],
.flatpickr-current-month input.cur-year[disabled]:hover {
  color: var(--text-secondary-color);
  opacity: 0.4;
}

.flatpickr-months {
  width: 100%;
  display: grid;
  grid-template-columns: 34px auto 34px;
  align-items: center;
}

.flatpickr-current-month {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
  padding: 0;
  font-size: 1.25rem;
  position: unset;
  width: 100%;
}

.flatpickr-months .flatpickr-prev-month,
.flatpickr-months .flatpickr-next-month {
  position: unset;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 34px;
  height: 34px;
  transition: color fill 1s;
}

.flatpickr-months .flatpickr-prev-month.flatpickr-disabled,
.flatpickr-months .flatpickr-next-month.flatpickr-disabled {
  display: block;
  visibility: hidden;
}

.flatpickr-months .flatpickr-prev-month svg path,
.flatpickr-months .flatpickr-next-month svg path {
  transition: fill 0.2s;
}

.flatpickr-current-month .flatpickr-monthDropdown-months {
  background: var(--button-secondary-color);
  border: none;
  border-radius: 0.5rem;
  padding: 0 4px;
  height: 2rem;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  text-align: center;
}

.flatpickr-current-month .numInputWrapper {
  width: 6.5ch;
  border-radius: 0.5rem;
}

.flatpickr-current-month input.cur-year {
  padding: 0;
  padding-left: 8px;
  border-radius: 0.5rem;
  height: 2rem;
}

.numInputWrapper span {
  width: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
}

.flatpickr-weekdays {
  border-bottom: 1px solid var(--text-secondary-color);
  border-top: 1px solid var(--text-secondary-color);
  height: 2rem;
}

span.flatpickr-weekday {
  background: var(--button-secondary-color);
  color: var(--text-primary-color);
}

.flatpickr-day {
  color: var(--text-primary-color);
}

.flatpickr-day.inRange,
.flatpickr-day.prevMonthDay.inRange,
.flatpickr-day.nextMonthDay.inRange,
.flatpickr-day.today.inRange,
.flatpickr-day.prevMonthDay.today.inRange,
.flatpickr-day.nextMonthDay.today.inRange,
.flatpickr-day:hover,
.flatpickr-day.prevMonthDay:hover,
.flatpickr-day.nextMonthDay:hover,
.flatpickr-day:focus,
.flatpickr-day.prevMonthDay:focus,
.flatpickr-day.nextMonthDay:focus {
  background: var(--button-primary-hover-color);
  border-color: var(--button-primary-hover-color);
}
.flatpickr-day:hover,
.flatpickr-day.nextMonthDay:hover {
  color: var(--text-primary-color);
}

.flatpickr-day.today {
  border-color: var(--text-secondary-color);
}

.flatpickr-day.today:hover,
.flatpickr-day.today:focus {
  border-color: var(--button-primary-hover-color);
  background: var(--button-primary-hover-color);
  color: var(--text-primary-color);
}

.flatpickr-day.selected,
.flatpickr-day.startRange,
.flatpickr-day.endRange,
.flatpickr-day.selected.inRange,
.flatpickr-day.startRange.inRange,
.flatpickr-day.endRange.inRange,
.flatpickr-day.selected:focus,
.flatpickr-day.startRange:focus,
.flatpickr-day.endRange:focus,
.flatpickr-day.selected:hover,
.flatpickr-day.startRange:hover,
.flatpickr-day.endRange:hover,
.flatpickr-day.selected.prevMonthDay,
.flatpickr-day.startRange.prevMonthDay,
.flatpickr-day.endRange.prevMonthDay,
.flatpickr-day.selected.nextMonthDay,
.flatpickr-day.startRange.nextMonthDay,
.flatpickr-day.endRange.nextMonthDay {
  background: var(--button-primary-color);
  color: var(--text-primary-color);
  border-color: var(--button-primary-color);
}

.flatpickr-day.flatpickr-disabled,
.flatpickr-day.flatpickr-disabled:hover,
.flatpickr-day.prevMonthDay,
.flatpickr-day.nextMonthDay,
.flatpickr-day.notAllowed,
.flatpickr-day.notAllowed.prevMonthDay,
.flatpickr-day.notAllowed.nextMonthDay {
  color: var(--text-secondary-color);
}

.flatpickr-day.flatpickr-disabled,
.flatpickr-day.flatpickr-disabled:hover {
  color: var(--text-secondary-color);
  opacity: 0.3;
}

.flatpickr-weekwrapper span.flatpickr-day,
.flatpickr-weekwrapper span.flatpickr-day:hover {
  color: var(--text-secondary-color);
}

.flatpickr-time .numInputWrapper span.arrowUp:after {
  border-bottom-color: var(--text-primary-color);
}
.flatpickr-time .numInputWrapper span.arrowDown:after {
  border-top-color: var(--text-primary-color);
}

.flatpickr-time input {
  color: var(--text-primary-color);
}

.flatpickr-time .flatpickr-time-separator,
.flatpickr-time .flatpickr-am-pm {
  color: var(--text-primary-color);
}

.flatpickr-time input:hover,
.flatpickr-time .flatpickr-am-pm:hover,
.flatpickr-time input:focus,
.flatpickr-time .flatpickr-am-pm:focus {
  background: var(--button-secondary-color);
}

.flatpickr-calendar.animate.open {
  -webkit-animation: none;
  animation: none;
}

.flatpickr-calendar.animate.open.arrowTop {
  -webkit-animation: fpFadeInUp 300ms cubic-bezier(0.23, 1, 0.32, 1);
  animation: fpFadeInUp 300ms cubic-bezier(0.23, 1, 0.32, 1);
}

.flatpickr-calendar.animate.open.arrowBottom {
  -webkit-animation: fpFadeInDown 300ms cubic-bezier(0.23, 1, 0.32, 1);
  animation: fpFadeInDown 300ms cubic-bezier(0.23, 1, 0.32, 1);
}

@media (max-width: 767px) {
  .flatpickr-calendar.animate.open.arrowTop,
  .flatpickr-calendar.animate.open.arrowBottom {
    -webkit-animation: none;
    animation: none;
  }
}

@-webkit-keyframes fpFadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 20px, 0);
    transform: translate3d(0, 20px, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fpFadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 20px, 0);
    transform: translate3d(0, 20px, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
/*#endregion flatpickr (input date)*/
