/*#region header*/
.header__container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 2rem;
  padding: 2rem;
}

.header__logo-section {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 1.5rem;
  height: 100%;
}

.header__title {
  font-size: 3.5rem;
  font-family: "Coiny", "Roboto", sans-serif;
  line-height: 1;
}

.header__logo {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 7rem;
  height: 7rem;
  border-radius: 50%;
  background-color: var(--button-secondary-color);
  flex-shrink: 0;
}

.header__logo-image {
  width: 5rem;
  height: auto;
}

.header__navbar {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

@media (max-width: 1023px) {
  .header__title {
    font-size: 3rem;
  }
}

@media (max-width: 767px) {
  .header__container {
    flex-direction: column;
    align-items: flex-start;
    gap: 3rem;
    padding: 0 1.5rem 1rem 1.5rem;
  }

  .header__logo-section {
    gap: 1rem;
  }

  .header__logo {
    width: 6rem;
    height: 6rem;
  }

  .header__logo-image {
    width: 4.5rem;
  }

  .header__title {
    font-size: 2.5rem;
  }

  .header__navbar,
  .header__navbar .btn {
    width: 100%;
  }

  .header__navbar .btn {
    font-size: 1.5rem;
  }
}

@media (max-width: 639px) {
  .header__container {
    gap: 2rem;
  }

  .header__logo-section {
    gap: 1rem;
  }

  .header__logo {
    width: 5rem;
    height: 5rem;
  }

  .header__logo-image {
    width: 3.5rem;
  }

  .header__title {
    font-size: 2rem;
  }

  .header__navbar .btn {
    font-size: 1.25rem;
    padding: 0.5rem 1rem;
  }
}
/*#endregion header*/
