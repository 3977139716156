/*#region form elements*/
.form {
  width: 100%;
  height: auto;
  font-family: "Sono", "Roboto", sans-serif;
  letter-spacing: 0;
  line-height: 1.4;
}

.form__header,
.form__content {
  margin-bottom: 1.75rem;
}

.form__title {
  font-family: inherit;
  font-weight: 600;
  line-height: 1.2;
  font-size: 1.5rem;
}

.form__title--md {
  font-size: 1.5rem;
}

.form__title--lg {
  font-size: 1.75rem;
}

.form__title--xl {
  font-size: 2rem;
}

.form__item-group {
  width: 100%;
  margin-bottom: 1.5rem;
}

.form__label {
  display: block;
  font-size: 1.25rem;
  color: var(--text-primary-color);
  margin-bottom: 0.5rem;
  font-weight: 600;
}

.form__sub-label {
  display: inline-block;
  opacity: 0.7;
  margin-left: 0.5rem;
}

.form__sub-label--sm {
  font-size: 0.75rem;
}

.form__item {
  width: 100%;
  font-family: inherit;
  border: 1px solid transparent;
  outline: none;
  background-color: var(--button-secondary-color);
  border-radius: 0.5rem;
  padding: 0.75rem 1rem;
  color: var(--text-primary-color);
  font-size: 1.25rem;
  transition: all 0.2s;
}

textarea.form__item {
  resize: none;
}

input.input.form__item {
  cursor: pointer;
}

.form__item::placeholder {
  color: var(--text-primary-color);
  opacity: 0.5;
}

.form__item:focus {
  border: 1px solid var(--text-secondary-color);
}

.form__actions {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 1.25rem;
  width: 100%;
}

.form__btn {
  font-family: inherit;
  font-weight: 600;
}
/*#endregion form elements*/
