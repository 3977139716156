/*#region empty content*/
.empty-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 2rem;
  width: 100%;
  height: auto;
  padding-top: 4rem;
}

.empty-content__text {
  font-family: "Sono", "Roboto", sans-serif;
  letter-spacing: 0;
  line-height: 1.4;
  font-size: 1.75rem;
  color: var(--text-primary-color);
  text-align: center;
  width: 100%;
}

.empty-content__icon {
  height: max-content;
  text-align: center;
  width: 100%;
  color: var(--text-primary-color);
  font-size: 1.5rem;
}
/*#endregion empty content*/
