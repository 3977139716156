/*#region sidebar*/
.sidebar__container,
.sidebar__tag-buttons {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  gap: 2rem;
  padding: 2rem;
}

.sidebar__tag-buttons {
  padding: unset;
  height: max-content;
  gap: 1rem;
}

.sidebar__tag-buttons button:first-of-type {
  margin-bottom: 1rem;
}

.sidebar__container .btn,
.sidebar__tag-buttons .btn {
  width: 100%;
}

@media (max-width: 767px) {
  .sidebar__container {
    padding: 0 1.5rem;
  }

  .sidebar__tag-buttons {
    overflow-x: auto;
    flex-direction: row;
    gap: 0.75rem;
    scroll-behavior: smooth;
  }

  .sidebar__tag-buttons::-webkit-scrollbar {
    height: 0;
  }

  .sidebar__tag-buttons .btn {
    font-size: 1.25rem;
    padding: 0.5rem 1rem;
  }

  .sidebar__tag-buttons .btn--tag-primary {
    margin-bottom: unset;
  }
}
/*#endregion sidebar*/
