* {
  padding: 0;
  margin: 0;
  list-style: none;
  border: 0;
  box-sizing: border-box;
}

/*#region scrollbar*/
/* Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: var(--button-secondary-color)
    var(--background-secondary-color);
}

/* Chrome, Safari, Edge */
::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: var(--background-secondary-color);
}

::-webkit-scrollbar-thumb {
  background: var(--button-secondary-color);
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: var(--button-secondary-hover-color);
}
/*#endregion scrollbar*/

html {
  scroll-behavior: smooth;
  overflow-y: overlay;
}

html,
body,
#app-root {
  height: 100%;
}

body {
  font-family: "Roboto", sans-serif;
  background-color: var(--background-primary-color);
  color: var(--text-primary-color);
}

.container {
  margin: 0 auto;
  width: 100%;
}

.grid-container {
  display: grid;
  grid-template-columns: 18rem 1fr;
  grid-template-rows: auto 1fr auto;
  grid-template-areas:
    "header header"
    "sidebar main"
    "footer footer";
  gap: 0;
  height: 100%;
  width: 100%;
}

.header {
  grid-area: header;
  position: sticky;
  top: 0;
  z-index: 10000;
  background-color: var(--background-primary-color);
}

.footer {
  grid-area: footer;
}

.sidebar {
  grid-area: sidebar;
  align-self: flex-start;
  position: sticky;
  top: 176px;
  z-index: 10000;
  background-color: var(--background-primary-color);
}

.main {
  grid-area: main;
}

@media (min-width: 640px) {
  .container {
    max-width: 640px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 768px;
  }
}

@media (min-width: 1024px) {
  .container {
    max-width: 1024px;
  }
}

@media (min-width: 1280px) {
  .container {
    max-width: 1280px;
  }
}

@media (min-width: 1536px) {
  .container {
    max-width: 1536px;
  }
}

@media (max-width: 767px) {
  .grid-container {
    grid-template-columns: 100%;
    grid-template-rows: auto auto 1fr auto;
    grid-template-areas:
      "header"
      "sidebar"
      "main"
      "footer";
  }

  .header {
    padding-top: 1rem;
  }
}
