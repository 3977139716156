/*#region loader*/
.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 24rem;
}

.loader__item {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  background: linear-gradient(
    0deg,
    rgb(246, 56, 220, 0.2) 33%,
    rgb(246, 56, 220) 100%
  );
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

.loader__item::after {
  content: "";
  box-sizing: border-box;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: var(--background-primary-color);
}

.loader__item--xl {
  width: 72px;
  height: 72px;
}

.loader__item--xl::after {
  width: 60px;
  height: 60px;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
/*#endregion loader*/
