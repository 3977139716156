@import "https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,500;1,300;1,400;1,500&display=swap";
@import "https://fonts.googleapis.com/css2?family=Coiny&family=Sono:wght@400;600&display=swap";
.flatpickr-calendar {
  opacity: 0;
  text-align: center;
  visibility: hidden;
  direction: ltr;
  box-sizing: border-box;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  background: #3f4458;
  border: 0;
  border-radius: 5px;
  width: 307.875px;
  padding: 0;
  font-size: 14px;
  line-height: 24px;
  animation: none;
  display: none;
  position: absolute;
  box-shadow: 1px 0 #20222c, -1px 0 #20222c, 0 1px #20222c, 0 -1px #20222c, 0 3px 13px #00000014;
}

.flatpickr-calendar.open, .flatpickr-calendar.inline {
  opacity: 1;
  visibility: visible;
  max-height: 640px;
}

.flatpickr-calendar.open {
  z-index: 99999;
  display: inline-block;
}

.flatpickr-calendar.animate.open {
  animation: .3s cubic-bezier(.23, 1, .32, 1) fpFadeInDown;
}

.flatpickr-calendar.inline {
  display: block;
  position: relative;
  top: 2px;
}

.flatpickr-calendar.static {
  position: absolute;
  top: calc(100% + 2px);
}

.flatpickr-calendar.static.open {
  z-index: 999;
  display: block;
}

.flatpickr-calendar.multiMonth .flatpickr-days .dayContainer:nth-child(n+1) .flatpickr-day.inRange:nth-child(7n+7) {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.flatpickr-calendar.multiMonth .flatpickr-days .dayContainer:nth-child(n+2) .flatpickr-day.inRange:nth-child(7n+1) {
  box-shadow: -2px 0 #e6e6e6, 5px 0 #e6e6e6;
}

.flatpickr-calendar .hasWeeks .dayContainer, .flatpickr-calendar .hasTime .dayContainer {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.flatpickr-calendar .hasWeeks .dayContainer {
  border-left: 0;
}

.flatpickr-calendar.hasTime .flatpickr-time {
  border-top: 1px solid #20222c;
  height: 40px;
}

.flatpickr-calendar.noCalendar.hasTime .flatpickr-time {
  height: auto;
}

.flatpickr-calendar:before, .flatpickr-calendar:after {
  pointer-events: none;
  content: "";
  border: solid #0000;
  width: 0;
  height: 0;
  display: block;
  position: absolute;
  left: 22px;
}

.flatpickr-calendar.rightMost:before, .flatpickr-calendar.arrowRight:before, .flatpickr-calendar.rightMost:after, .flatpickr-calendar.arrowRight:after {
  left: auto;
  right: 22px;
}

.flatpickr-calendar.arrowCenter:before, .flatpickr-calendar.arrowCenter:after {
  left: 50%;
  right: 50%;
}

.flatpickr-calendar:before {
  border-width: 5px;
  margin: 0 -5px;
}

.flatpickr-calendar:after {
  border-width: 4px;
  margin: 0 -4px;
}

.flatpickr-calendar.arrowTop:before, .flatpickr-calendar.arrowTop:after {
  bottom: 100%;
}

.flatpickr-calendar.arrowTop:before {
  border-bottom-color: #20222c;
}

.flatpickr-calendar.arrowTop:after {
  border-bottom-color: #3f4458;
}

.flatpickr-calendar.arrowBottom:before, .flatpickr-calendar.arrowBottom:after {
  top: 100%;
}

.flatpickr-calendar.arrowBottom:before {
  border-top-color: #20222c;
}

.flatpickr-calendar.arrowBottom:after {
  border-top-color: #3f4458;
}

.flatpickr-calendar:focus {
  outline: 0;
}

.flatpickr-wrapper {
  display: inline-block;
  position: relative;
}

.flatpickr-months {
  display: flex;
}

.flatpickr-months .flatpickr-month {
  color: #fff;
  fill: #fff;
  text-align: center;
  -webkit-user-select: none;
  user-select: none;
  background: #3f4458;
  flex: 1;
  height: 34px;
  line-height: 1;
  position: relative;
  overflow: hidden;
}

.flatpickr-months .flatpickr-prev-month, .flatpickr-months .flatpickr-next-month {
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
  z-index: 3;
  color: #fff;
  fill: #fff;
  height: 34px;
  padding: 10px;
  text-decoration: none;
  position: absolute;
  top: 0;
}

.flatpickr-months .flatpickr-prev-month.flatpickr-disabled, .flatpickr-months .flatpickr-next-month.flatpickr-disabled {
  display: none;
}

.flatpickr-months .flatpickr-prev-month i, .flatpickr-months .flatpickr-next-month i {
  position: relative;
}

.flatpickr-months .flatpickr-prev-month.flatpickr-prev-month, .flatpickr-months .flatpickr-next-month.flatpickr-prev-month {
  left: 0;
}

.flatpickr-months .flatpickr-prev-month.flatpickr-next-month, .flatpickr-months .flatpickr-next-month.flatpickr-next-month {
  right: 0;
}

.flatpickr-months .flatpickr-prev-month:hover, .flatpickr-months .flatpickr-next-month:hover {
  color: #eee;
}

.flatpickr-months .flatpickr-prev-month:hover svg, .flatpickr-months .flatpickr-next-month:hover svg {
  fill: #f64747;
}

.flatpickr-months .flatpickr-prev-month svg, .flatpickr-months .flatpickr-next-month svg {
  width: 14px;
  height: 14px;
}

.flatpickr-months .flatpickr-prev-month svg path, .flatpickr-months .flatpickr-next-month svg path {
  fill: inherit;
  transition: fill .1s;
}

.numInputWrapper {
  height: auto;
  position: relative;
}

.numInputWrapper input, .numInputWrapper span {
  display: inline-block;
}

.numInputWrapper input {
  width: 100%;
}

.numInputWrapper input::-ms-clear {
  display: none;
}

:is(.numInputWrapper input::-webkit-outer-spin-button, .numInputWrapper input::-webkit-inner-spin-button) {
  -webkit-appearance: none;
  margin: 0;
}

.numInputWrapper span {
  opacity: 0;
  cursor: pointer;
  box-sizing: border-box;
  border: 1px solid #ffffff26;
  width: 14px;
  height: 50%;
  padding: 0 4px 0 2px;
  line-height: 50%;
  position: absolute;
  right: 0;
}

.numInputWrapper span:hover {
  background: #c0bba71a;
}

.numInputWrapper span:active {
  background: #c0bba733;
}

.numInputWrapper span:after {
  content: "";
  display: block;
  position: absolute;
}

.numInputWrapper span.arrowUp {
  border-bottom: 0;
  top: 0;
}

.numInputWrapper span.arrowUp:after {
  border-bottom: 4px solid #fff9;
  border-left: 4px solid #0000;
  border-right: 4px solid #0000;
  top: 26%;
}

.numInputWrapper span.arrowDown {
  top: 50%;
}

.numInputWrapper span.arrowDown:after {
  border-top: 4px solid #fff9;
  border-left: 4px solid #0000;
  border-right: 4px solid #0000;
  top: 40%;
}

.numInputWrapper span svg {
  width: inherit;
  height: auto;
}

.numInputWrapper span svg path {
  fill: #ffffff80;
}

.numInputWrapper:hover {
  background: #c0bba70d;
}

.numInputWrapper:hover span {
  opacity: 1;
}

.flatpickr-current-month {
  font-size: 135%;
  line-height: inherit;
  color: inherit;
  text-align: center;
  width: 75%;
  height: 34px;
  padding: 7.48px 0 0;
  font-weight: 300;
  line-height: 1;
  display: inline-block;
  position: absolute;
  left: 12.5%;
  transform: translate3d(0, 0, 0);
}

.flatpickr-current-month span.cur-month {
  color: inherit;
  margin-left: .5ch;
  padding: 0;
  font-family: inherit;
  font-weight: 700;
  display: inline-block;
}

.flatpickr-current-month span.cur-month:hover {
  background: #c0bba70d;
}

.flatpickr-current-month .numInputWrapper {
  width: 7ch�;
  width: 6ch;
  display: inline-block;
}

.flatpickr-current-month .numInputWrapper span.arrowUp:after {
  border-bottom-color: #fff;
}

.flatpickr-current-month .numInputWrapper span.arrowDown:after {
  border-top-color: #fff;
}

.flatpickr-current-month input.cur-year {
  box-sizing: border-box;
  color: inherit;
  cursor: text;
  font-size: inherit;
  font-family: inherit;
  font-weight: 300;
  line-height: inherit;
  vertical-align: initial;
  appearance: textfield;
  background: none;
  border: 0;
  border-radius: 0;
  height: auto;
  margin: 0;
  padding: 0 0 0 .5ch;
  display: inline-block;
}

.flatpickr-current-month input.cur-year:focus {
  outline: 0;
}

.flatpickr-current-month input.cur-year[disabled], .flatpickr-current-month input.cur-year[disabled]:hover {
  color: #ffffff80;
  pointer-events: none;
  background: none;
  font-size: 100%;
}

.flatpickr-current-month .flatpickr-monthDropdown-months {
  appearance: menulist;
  box-sizing: border-box;
  color: inherit;
  cursor: pointer;
  font-size: inherit;
  font-family: inherit;
  font-weight: 300;
  line-height: inherit;
  vertical-align: initial;
  background: #3f4458;
  border: none;
  border-radius: 0;
  outline: none;
  width: auto;
  height: auto;
  margin: -1px 0 0;
  padding: 0 0 0 .5ch;
  position: relative;
}

.flatpickr-current-month .flatpickr-monthDropdown-months:focus, .flatpickr-current-month .flatpickr-monthDropdown-months:active {
  outline: none;
}

.flatpickr-current-month .flatpickr-monthDropdown-months:hover {
  background: #c0bba70d;
}

.flatpickr-current-month .flatpickr-monthDropdown-months .flatpickr-monthDropdown-month {
  background-color: #3f4458;
  outline: none;
  padding: 0;
}

.flatpickr-weekdays {
  text-align: center;
  background: none;
  align-items: center;
  width: 100%;
  height: 28px;
  display: flex;
  overflow: hidden;
}

.flatpickr-weekdays .flatpickr-weekdaycontainer {
  flex: 1;
  display: flex;
}

span.flatpickr-weekday {
  cursor: default;
  color: #fff;
  text-align: center;
  background: #3f4458;
  flex: 1;
  margin: 0;
  font-size: 90%;
  font-weight: bolder;
  line-height: 1;
  display: block;
}

.dayContainer, .flatpickr-weeks {
  padding: 1px 0 0;
}

.flatpickr-days {
  align-items: flex-start;
  width: 307.875px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.flatpickr-days:focus {
  outline: 0;
}

.dayContainer {
  text-align: left;
  box-sizing: border-box;
  opacity: 1;
  outline: 0;
  flex-wrap: wrap;
  justify-content: space-around;
  width: 307.875px;
  min-width: 307.875px;
  max-width: 307.875px;
  padding: 0;
  display: flex;
  transform: translate3d(0, 0, 0);
}

.dayContainer + .dayContainer {
  box-shadow: -1px 0 #20222c;
}

.flatpickr-day {
  box-sizing: border-box;
  color: #fffffff2;
  cursor: pointer;
  text-align: center;
  background: none;
  border: 1px solid #0000;
  border-radius: 150px;
  flex-basis: 14.2857%;
  justify-content: center;
  width: 14.2857%;
  max-width: 39px;
  height: 39px;
  margin: 0;
  font-weight: 400;
  line-height: 39px;
  display: inline-block;
  position: relative;
}

.flatpickr-day.inRange, .flatpickr-day.prevMonthDay.inRange, .flatpickr-day.nextMonthDay.inRange, .flatpickr-day.today.inRange, .flatpickr-day.prevMonthDay.today.inRange, .flatpickr-day.nextMonthDay.today.inRange, .flatpickr-day:hover, .flatpickr-day.prevMonthDay:hover, .flatpickr-day.nextMonthDay:hover, .flatpickr-day:focus, .flatpickr-day.prevMonthDay:focus, .flatpickr-day.nextMonthDay:focus {
  cursor: pointer;
  background: #646c8c;
  border-color: #646c8c;
  outline: 0;
}

.flatpickr-day.today {
  border-color: #eee;
}

.flatpickr-day.today:hover, .flatpickr-day.today:focus {
  color: #3f4458;
  background: #eee;
  border-color: #eee;
}

.flatpickr-day.selected, .flatpickr-day.startRange, .flatpickr-day.endRange, .flatpickr-day.selected.inRange, .flatpickr-day.startRange.inRange, .flatpickr-day.endRange.inRange, .flatpickr-day.selected:focus, .flatpickr-day.startRange:focus, .flatpickr-day.endRange:focus, .flatpickr-day.selected:hover, .flatpickr-day.startRange:hover, .flatpickr-day.endRange:hover, .flatpickr-day.selected.prevMonthDay, .flatpickr-day.startRange.prevMonthDay, .flatpickr-day.endRange.prevMonthDay, .flatpickr-day.selected.nextMonthDay, .flatpickr-day.startRange.nextMonthDay, .flatpickr-day.endRange.nextMonthDay {
  -webkit-box-shadow: none;
  box-shadow: none;
  color: #fff;
  background: #80cbc4;
  border-color: #80cbc4;
}

.flatpickr-day.selected.startRange, .flatpickr-day.startRange.startRange, .flatpickr-day.endRange.startRange {
  border-radius: 50px 0 0 50px;
}

.flatpickr-day.selected.endRange, .flatpickr-day.startRange.endRange, .flatpickr-day.endRange.endRange {
  border-radius: 0 50px 50px 0;
}

.flatpickr-day.selected.startRange + .endRange:not(:nth-child(7n+1)), .flatpickr-day.startRange.startRange + .endRange:not(:nth-child(7n+1)), .flatpickr-day.endRange.startRange + .endRange:not(:nth-child(7n+1)) {
  box-shadow: -10px 0 #80cbc4;
}

.flatpickr-day.selected.startRange.endRange, .flatpickr-day.startRange.startRange.endRange, .flatpickr-day.endRange.startRange.endRange {
  border-radius: 50px;
}

.flatpickr-day.inRange {
  border-radius: 0;
  box-shadow: -5px 0 #646c8c, 5px 0 #646c8c;
}

.flatpickr-day.flatpickr-disabled, .flatpickr-day.flatpickr-disabled:hover, .flatpickr-day.prevMonthDay, .flatpickr-day.nextMonthDay, .flatpickr-day.notAllowed, .flatpickr-day.notAllowed.prevMonthDay, .flatpickr-day.notAllowed.nextMonthDay {
  color: #ffffff4d;
  cursor: default;
  background: none;
  border-color: #0000;
}

.flatpickr-day.flatpickr-disabled, .flatpickr-day.flatpickr-disabled:hover {
  cursor: not-allowed;
  color: #ffffff1a;
}

.flatpickr-day.week.selected {
  border-radius: 0;
  box-shadow: -5px 0 #80cbc4, 5px 0 #80cbc4;
}

.flatpickr-day.hidden {
  visibility: hidden;
}

.rangeMode .flatpickr-day {
  margin-top: 1px;
}

.flatpickr-weekwrapper {
  float: left;
}

.flatpickr-weekwrapper .flatpickr-weeks {
  padding: 0 12px;
  box-shadow: 1px 0 #20222c;
}

.flatpickr-weekwrapper .flatpickr-weekday {
  float: none;
  width: 100%;
  line-height: 28px;
}

.flatpickr-weekwrapper span.flatpickr-day, .flatpickr-weekwrapper span.flatpickr-day:hover {
  color: #ffffff4d;
  cursor: default;
  background: none;
  border: none;
  width: 100%;
  max-width: none;
  display: block;
}

.flatpickr-innerContainer {
  box-sizing: border-box;
  display: flex;
  overflow: hidden;
}

.flatpickr-rContainer {
  box-sizing: border-box;
  padding: 0;
  display: inline-block;
}

.flatpickr-time {
  text-align: center;
  box-sizing: border-box;
  outline: 0;
  height: 0;
  max-height: 40px;
  line-height: 40px;
  display: flex;
  overflow: hidden;
}

.flatpickr-time:after {
  content: "";
  clear: both;
  display: table;
}

.flatpickr-time .numInputWrapper {
  float: left;
  flex: 1;
  width: 40%;
  height: 40px;
}

.flatpickr-time .numInputWrapper span.arrowUp:after {
  border-bottom-color: #fffffff2;
}

.flatpickr-time .numInputWrapper span.arrowDown:after {
  border-top-color: #fffffff2;
}

.flatpickr-time.hasSeconds .numInputWrapper {
  width: 26%;
}

.flatpickr-time.time24hr .numInputWrapper {
  width: 49%;
}

.flatpickr-time input {
  -webkit-box-shadow: none;
  box-shadow: none;
  text-align: center;
  height: inherit;
  line-height: inherit;
  color: #fffffff2;
  box-sizing: border-box;
  appearance: textfield;
  background: none;
  border: 0;
  border-radius: 0;
  margin: 0;
  padding: 0;
  font-size: 14px;
  position: relative;
}

.flatpickr-time input.flatpickr-hour {
  font-weight: bold;
}

.flatpickr-time input.flatpickr-minute, .flatpickr-time input.flatpickr-second {
  font-weight: 400;
}

.flatpickr-time input:focus {
  border: 0;
  outline: 0;
}

.flatpickr-time .flatpickr-time-separator, .flatpickr-time .flatpickr-am-pm {
  height: inherit;
  float: left;
  line-height: inherit;
  color: #fffffff2;
  -webkit-user-select: none;
  user-select: none;
  align-self: center;
  width: 2%;
  font-weight: bold;
}

.flatpickr-time .flatpickr-am-pm {
  cursor: pointer;
  text-align: center;
  outline: 0;
  width: 18%;
  font-weight: 400;
}

.flatpickr-time input:hover, .flatpickr-time .flatpickr-am-pm:hover, .flatpickr-time input:focus, .flatpickr-time .flatpickr-am-pm:focus {
  background: #6a7395;
}

.flatpickr-input[readonly] {
  cursor: pointer;
}

@keyframes fpFadeInDown {
  from {
    opacity: 0;
    transform: translate3d(0, -20px, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.choices {
  margin-bottom: 24px;
  font-size: 16px;
  position: relative;
  overflow: hidden;
}

.choices:focus {
  outline: none;
}

.choices:last-child {
  margin-bottom: 0;
}

.choices.is-open {
  overflow: visible;
}

.choices.is-disabled .choices__inner, .choices.is-disabled .choices__input {
  cursor: not-allowed;
  -webkit-user-select: none;
  user-select: none;
  background-color: #eaeaea;
}

.choices.is-disabled .choices__item {
  cursor: not-allowed;
}

.choices [hidden] {
  display: none !important;
}

.choices[data-type*="select-one"] {
  cursor: pointer;
}

.choices[data-type*="select-one"] .choices__inner {
  padding-bottom: 7.5px;
}

.choices[data-type*="select-one"] .choices__input {
  background-color: #fff;
  border-bottom: 1px solid #ddd;
  width: 100%;
  margin: 0;
  padding: 10px;
  display: block;
}

.choices[data-type*="select-one"] .choices__button {
  opacity: .25;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjEiIGhlaWdodD0iMjEiIHZpZXdCb3g9IjAgMCAyMSAyMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48ZyBmaWxsPSIjMDAwIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPjxwYXRoIGQ9Ik0yLjU5Mi4wNDRsMTguMzY0IDE4LjM2NC0yLjU0OCAyLjU0OEwuMDQ0IDIuNTkyeiIvPjxwYXRoIGQ9Ik0wIDE4LjM2NEwxOC4zNjQgMGwyLjU0OCAyLjU0OEwyLjU0OCAyMC45MTJ6Ii8+PC9nPjwvc3ZnPg==");
  background-size: 8px;
  border-radius: 10em;
  width: 20px;
  height: 20px;
  margin-top: -10px;
  margin-right: 25px;
  padding: 0;
  position: absolute;
  top: 50%;
  right: 0;
}

.choices[data-type*="select-one"] .choices__button:hover, .choices[data-type*="select-one"] .choices__button:focus {
  opacity: 1;
}

.choices[data-type*="select-one"] .choices__button:focus {
  box-shadow: 0 0 0 2px #00bcd4;
}

.choices[data-type*="select-one"] .choices__item[data-value=""] .choices__button {
  display: none;
}

.choices[data-type*="select-one"]:after {
  content: "";
  pointer-events: none;
  border: 5px solid #0000;
  border-top-color: #333;
  width: 0;
  height: 0;
  margin-top: -2.5px;
  position: absolute;
  top: 50%;
  right: 11.5px;
}

.choices[data-type*="select-one"].is-open:after {
  border-color: #0000 #0000 #333;
  margin-top: -7.5px;
}

.choices[data-type*="select-one"][dir="rtl"]:after {
  left: 11.5px;
  right: auto;
}

.choices[data-type*="select-one"][dir="rtl"] .choices__button {
  margin-left: 25px;
  margin-right: 0;
  left: 0;
  right: auto;
}

.choices[data-type*="select-multiple"] .choices__inner, .choices[data-type*="text"] .choices__inner {
  cursor: text;
}

.choices[data-type*="select-multiple"] .choices__button, .choices[data-type*="text"] .choices__button {
  opacity: .75;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjEiIGhlaWdodD0iMjEiIHZpZXdCb3g9IjAgMCAyMSAyMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48ZyBmaWxsPSIjRkZGIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPjxwYXRoIGQ9Ik0yLjU5Mi4wNDRsMTguMzY0IDE4LjM2NC0yLjU0OCAyLjU0OEwuMDQ0IDIuNTkyeiIvPjxwYXRoIGQ9Ik0wIDE4LjM2NEwxOC4zNjQgMGwyLjU0OCAyLjU0OEwyLjU0OCAyMC45MTJ6Ii8+PC9nPjwvc3ZnPg==");
  background-size: 8px;
  border-left: 1px solid #008fa1;
  border-radius: 0;
  width: 8px;
  margin: 0 -4px 0 8px;
  padding-left: 16px;
  line-height: 1;
  display: inline-block;
  position: relative;
}

.choices[data-type*="select-multiple"] .choices__button:hover, .choices[data-type*="select-multiple"] .choices__button:focus, .choices[data-type*="text"] .choices__button:hover, .choices[data-type*="text"] .choices__button:focus {
  opacity: 1;
}

.choices__inner {
  vertical-align: top;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 2.5px;
  width: 100%;
  min-height: 44px;
  padding: 7.5px 7.5px 3.75px;
  font-size: 14px;
  display: inline-block;
  overflow: hidden;
}

.is-focused .choices__inner, .is-open .choices__inner {
  border-color: #b7b7b7;
}

.is-open .choices__inner {
  border-radius: 2.5px 2.5px 0 0;
}

.is-flipped.is-open .choices__inner {
  border-radius: 0 0 2.5px 2.5px;
}

.choices__list {
  margin: 0;
  padding-left: 0;
  list-style: none;
}

.choices__list--single {
  width: 100%;
  padding: 4px 16px 4px 4px;
  display: inline-block;
}

[dir="rtl"] .choices__list--single {
  padding-left: 16px;
  padding-right: 4px;
}

.choices__list--single .choices__item {
  width: 100%;
}

.choices__list--multiple {
  display: inline;
}

.choices__list--multiple .choices__item {
  vertical-align: middle;
  color: #fff;
  word-break: break-all;
  box-sizing: border-box;
  background-color: #00bcd4;
  border: 1px solid #00a5bb;
  border-radius: 20px;
  margin-bottom: 3.75px;
  margin-right: 3.75px;
  padding: 4px 10px;
  font-size: 12px;
  font-weight: 500;
  display: inline-block;
}

.choices__list--multiple .choices__item[data-deletable] {
  padding-right: 5px;
}

[dir="rtl"] .choices__list--multiple .choices__item {
  margin-left: 3.75px;
  margin-right: 0;
}

.choices__list--multiple .choices__item.is-highlighted {
  background-color: #00a5bb;
  border: 1px solid #008fa1;
}

.is-disabled .choices__list--multiple .choices__item {
  background-color: #aaa;
  border: 1px solid #919191;
}

.choices__list--dropdown, .choices__list[aria-expanded] {
  visibility: hidden;
  z-index: 1;
  word-break: break-all;
  will-change: visibility;
  background-color: #fff;
  border: 1px solid #ddd;
  border-bottom-right-radius: 2.5px;
  border-bottom-left-radius: 2.5px;
  width: 100%;
  margin-top: -1px;
  position: absolute;
  top: 100%;
  overflow: hidden;
}

.is-active.choices__list--dropdown, .is-active.choices__list[aria-expanded] {
  visibility: visible;
}

.is-open .choices__list--dropdown, .is-open .choices__list[aria-expanded] {
  border-color: #b7b7b7;
}

.is-flipped .choices__list--dropdown, .is-flipped .choices__list[aria-expanded] {
  border-radius: .25rem .25rem 0 0;
  margin-top: 0;
  margin-bottom: -1px;
  top: auto;
  bottom: 100%;
}

.choices__list--dropdown .choices__list, .choices__list[aria-expanded] .choices__list {
  -webkit-overflow-scrolling: touch;
  will-change: scroll-position;
  max-height: 300px;
  position: relative;
  overflow: auto;
}

.choices__list--dropdown .choices__item, .choices__list[aria-expanded] .choices__item {
  padding: 10px;
  font-size: 14px;
  position: relative;
}

[dir="rtl"] .choices__list--dropdown .choices__item, [dir="rtl"] .choices__list[aria-expanded] .choices__item {
  text-align: right;
}

@media (width >= 640px) {
  .choices__list--dropdown .choices__item--selectable, .choices__list[aria-expanded] .choices__item--selectable {
    padding-right: 100px;
  }

  .choices__list--dropdown .choices__item--selectable:after, .choices__list[aria-expanded] .choices__item--selectable:after {
    content: attr(data-select-text);
    opacity: 0;
    font-size: 12px;
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
  }

  [dir="rtl"] .choices__list--dropdown .choices__item--selectable, [dir="rtl"] .choices__list[aria-expanded] .choices__item--selectable {
    text-align: right;
    padding-left: 100px;
    padding-right: 10px;
  }

  [dir="rtl"] .choices__list--dropdown .choices__item--selectable:after, [dir="rtl"] .choices__list[aria-expanded] .choices__item--selectable:after {
    left: 10px;
    right: auto;
  }
}

.choices__list--dropdown .choices__item--selectable.is-highlighted, .choices__list[aria-expanded] .choices__item--selectable.is-highlighted {
  background-color: #f2f2f2;
}

.choices__list--dropdown .choices__item--selectable.is-highlighted:after, .choices__list[aria-expanded] .choices__item--selectable.is-highlighted:after {
  opacity: .5;
}

.choices__item {
  cursor: default;
}

.choices__item--selectable {
  cursor: pointer;
}

.choices__item--disabled {
  cursor: not-allowed;
  -webkit-user-select: none;
  user-select: none;
  opacity: .5;
}

.choices__heading {
  color: gray;
  border-bottom: 1px solid #f7f7f7;
  padding: 10px;
  font-size: 12px;
  font-weight: 600;
}

.choices__button {
  text-indent: -9999px;
  appearance: none;
  cursor: pointer;
  background-color: #0000;
  background-position: center;
  background-repeat: no-repeat;
  border: 0;
}

.choices__button:focus {
  outline: none;
}

.choices__input {
  vertical-align: baseline;
  background-color: #f9f9f9;
  border: 0;
  border-radius: 0;
  max-width: 100%;
  margin-bottom: 5px;
  padding: 4px 0 4px 2px;
  font-size: 14px;
  display: inline-block;
}

.choices__input:focus {
  outline: 0;
}

:is(.choices__input::-webkit-search-decoration, .choices__input::-webkit-search-cancel-button, .choices__input::-webkit-search-results-button, .choices__input::-webkit-search-results-decoration) {
  display: none;
}

:is(.choices__input::-ms-clear, .choices__input::-ms-reveal) {
  width: 0;
  height: 0;
  display: none;
}

[dir="rtl"] .choices__input {
  padding-left: 0;
  padding-right: 2px;
}

.choices__placeholder {
  opacity: .5;
}

:root {
  --text-primary-color: #e5e5e5;
  --text-secondary-color: #999;
  --background-primary-color: #200f21;
  --background-secondary-color: #382039;
  --button-primary-color: #f638dc;
  --button-primary-hover-color: #f76ff1;
  --button-primary-active-color: #e419c9;
  --button-secondary-color: #5a3d5c;
  --button-secondary-hover-color: #7a546a;
  --button-secondary-active-color: #3f2b3d;
  --button-text-color: #e5e5e5;
  --tag-button-primary-color: #5c5470;
  --tag-button-primary-hover-color: #706a85;
  --tag-button-primary-active-color: #4a425a;
  --tag-button-secondary-color: #352f44;
  --tag-button-secondary-hover-color: #534b61;
  --tag-button-secondary-active-color: #221d2e;
  --url-text-primary-color: #e5e5e5;
  --url-text-primary-hover-color: #f2f2f2;
  --url-text-primary-active-color: #b3b3b3;
  --url-text-primary-visited-color: #ccc;
  --url-text-secondary-color: #999;
  --url-text-secondary-hover-color: #b3b3b3;
  --url-text-secondary-active-color: #666;
  --url-text-secondary-visited-color: #666;
  --erorr-primary-color: #e94560;
  --error-secondary-color: #ed2b2a;
}

* {
  box-sizing: border-box;
  scrollbar-width: thin;
  scrollbar-color: var(--button-secondary-color) var(--background-secondary-color);
  border: 0;
  margin: 0;
  padding: 0;
  list-style: none;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: var(--background-secondary-color);
}

::-webkit-scrollbar-thumb {
  background: var(--button-secondary-color);
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: var(--button-secondary-hover-color);
}

html {
  scroll-behavior: smooth;
  overflow-y: overlay;
}

html, body, #app-root {
  height: 100%;
}

body {
  background-color: var(--background-primary-color);
  color: var(--text-primary-color);
  font-family: Roboto, sans-serif;
}

.container {
  width: 100%;
  margin: 0 auto;
}

.grid-container {
  grid-template: "header header"
                 "sidebar main" 1fr
                 "footer footer"
                 / 18rem 1fr;
  gap: 0;
  width: 100%;
  height: 100%;
  display: grid;
}

.header {
  z-index: 10000;
  background-color: var(--background-primary-color);
  grid-area: header;
  position: sticky;
  top: 0;
}

.footer {
  grid-area: footer;
}

.sidebar {
  z-index: 10000;
  background-color: var(--background-primary-color);
  grid-area: sidebar;
  align-self: flex-start;
  position: sticky;
  top: 176px;
}

.main {
  grid-area: main;
}

@media (width >= 640px) {
  .container {
    max-width: 640px;
  }
}

@media (width >= 768px) {
  .container {
    max-width: 768px;
  }
}

@media (width >= 1024px) {
  .container {
    max-width: 1024px;
  }
}

@media (width >= 1280px) {
  .container {
    max-width: 1280px;
  }
}

@media (width >= 1536px) {
  .container {
    max-width: 1536px;
  }
}

@media (width <= 767px) {
  .grid-container {
    grid-template: "header"
                   "sidebar"
                   "main" 1fr
                   "footer"
                   / 100%;
  }

  .header {
    padding-top: 1rem;
  }
}

.header__container {
  justify-content: space-between;
  align-items: center;
  gap: 2rem;
  width: 100%;
  padding: 2rem;
  display: flex;
}

.header__logo-section {
  justify-content: flex-start;
  align-items: center;
  gap: 1.5rem;
  height: 100%;
  display: flex;
}

.header__title {
  font-family: Coiny, Roboto, sans-serif;
  font-size: 3.5rem;
  line-height: 1;
}

.header__logo {
  background-color: var(--button-secondary-color);
  border-radius: 50%;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  width: 7rem;
  height: 7rem;
  display: flex;
}

.header__logo-image {
  width: 5rem;
  height: auto;
}

.header__navbar {
  justify-content: center;
  align-items: center;
  height: 100%;
  display: flex;
}

@media (width <= 1023px) {
  .header__title {
    font-size: 3rem;
  }
}

@media (width <= 767px) {
  .header__container {
    flex-direction: column;
    align-items: flex-start;
    gap: 3rem;
    padding: 0 1.5rem 1rem;
  }

  .header__logo-section {
    gap: 1rem;
  }

  .header__logo {
    width: 6rem;
    height: 6rem;
  }

  .header__logo-image {
    width: 4.5rem;
  }

  .header__title {
    font-size: 2.5rem;
  }

  .header__navbar, .header__navbar .btn {
    width: 100%;
  }

  .header__navbar .btn {
    font-size: 1.5rem;
  }
}

@media (width <= 639px) {
  .header__container {
    gap: 2rem;
  }

  .header__logo-section {
    gap: 1rem;
  }

  .header__logo {
    width: 5rem;
    height: 5rem;
  }

  .header__logo-image {
    width: 3.5rem;
  }

  .header__title {
    font-size: 2rem;
  }

  .header__navbar .btn {
    padding: .5rem 1rem;
    font-size: 1.25rem;
  }
}

.main__container, .predictions {
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 1.5rem;
  width: 100%;
  padding: 2rem;
  display: flex;
}

.predictions {
  padding: unset;
  gap: 1.25rem;
}

.predictions__item {
  grid-template-columns: 1fr auto auto;
  grid-template-areas: "content tag buttons"
                       "dates dates dates";
  gap: 1.5rem;
  display: grid;
}

.predictions__item-content-label {
  font-size: inherit;
  color: var(--erorr-primary-color);
  font-family: inherit;
  display: none;
}

.predictions__item--expired {
  opacity: .5;
}

.predictions__item--expired .predictions__item-content-label {
  display: inline-block;
}

.predictions__item-content {
  letter-spacing: 0;
  text-transform: capitalize;
  grid-area: content;
  font-family: Sono, Roboto, sans-serif;
  font-size: 1.25rem;
  line-height: 1.4;
}

.predictions__item-tag-label {
  text-transform: capitalize;
  background-color: var(--tag-button-secondary-color);
  border-radius: 1.5rem;
  grid-area: tag;
  align-self: center;
  width: max-content;
  padding: .75rem 1rem;
  font-family: Coiny, Roboto, sans-serif;
  line-height: 1;
  display: inline-block;
}

.predictions__item-vote-buttons {
  flex-direction: column;
  grid-area: buttons;
  justify-content: flex-start;
  align-self: center;
  align-items: center;
  gap: 1rem;
  display: flex;
}

.predictions__item-vote-button-item {
  cursor: pointer;
  background-color: var(--button-secondary-color);
  color: var(--text-primary-color);
  border: none;
  border-radius: 1.5rem;
  justify-content: flex-start;
  align-items: center;
  gap: .5rem;
  width: 100%;
  min-width: 6rem;
  height: 2.5rem;
  padding: .5rem 1rem;
  font-family: Sono, Roboto, sans-serif;
  font-size: 1.25rem;
  font-weight: 600;
  line-height: 1;
  text-decoration: none;
  transition: background-color .2s, color .2s;
  display: flex;
}

.predictions__item-vote-button-item:hover {
  background-color: var(--button-primary-color);
}

.predictions__item-vote-button-item:active {
  background-color: var(--button-primary-active-color);
}

.predictions__item-vote-button-item:disabled {
  opacity: .5;
  cursor: not-allowed;
  pointer-events: none;
}

.predictions__item-vote-button-item .fa-solid, .predictions__item-vote-button-item:hover .fa-regular {
  display: none;
}

.predictions__item-vote-button-item:hover .fa-solid, .predictions__item-vote-button-item .fa-regular {
  display: var(--fa-display, inline-block);
}

.predictions__item-vote-button-item .fa-thumbs-down {
  transform: scaleX(-1);
}

.predictions__item-vote-counter {
  margin: 0 auto;
  display: inline-block;
}

.predictions__item-dates {
  grid-area: dates;
}

.predictions__item-realization-time {
  letter-spacing: 0;
  color: var(--url-text-secondary-active-color);
  margin-top: .5rem;
  font-family: Sono, Roboto, sans-serif;
  font-size: .75rem;
  display: inline-block;
}

.predictions__item-countdown {
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
  display: flex;
}

.predictions__item-countdown-item {
  letter-spacing: 0;
  text-transform: capitalize;
  color: var(--text-secondary-color);
  justify-content: flex-start;
  align-items: center;
  gap: .25rem;
  font-family: Sono, Roboto, sans-serif;
  display: flex;
}

.predictions__item-countdown-item--error, .predictions__item-realization-time--error {
  color: var(--erorr-primary-color);
}

.predictions__item-countdown-item-number {
  font-size: 1.25rem;
}

.predictions__item-countdown-item-text {
  font-size: .75rem;
}

@media (width >= 1024px) and (width <= 1279px) {
  .predictions__item {
    grid-template-columns: auto auto;
    grid-template-areas: "content buttons"
                         "tag tag"
                         "dates dates";
    gap: 1.25rem 1rem;
  }
}

@media (width <= 1023px) {
  .predictions__item {
    grid-template-columns: auto;
    grid-template-areas: "content"
                         "buttons"
                         "tag"
                         "dates";
    gap: 1.25rem 1rem;
  }

  .predictions__item-vote-buttons {
    flex-direction: row;
  }

  .predictions__item-countdown-item {
    flex-direction: column;
    gap: .1rem;
  }
}

@media (width <= 767px) {
  .main__container {
    padding: 0 1.5rem;
  }
}

.sidebar__container, .sidebar__tag-buttons {
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 2rem;
  width: 100%;
  height: 100%;
  padding: 2rem;
  display: flex;
}

.sidebar__tag-buttons {
  padding: unset;
  gap: 1rem;
  height: max-content;
}

.sidebar__tag-buttons button:first-of-type {
  margin-bottom: 1rem;
}

.sidebar__container .btn, .sidebar__tag-buttons .btn {
  width: 100%;
}

@media (width <= 767px) {
  .sidebar__container {
    padding: 0 1.5rem;
  }

  .sidebar__tag-buttons {
    scroll-behavior: smooth;
    flex-direction: row;
    gap: .75rem;
    overflow-x: auto;
  }

  .sidebar__tag-buttons::-webkit-scrollbar {
    height: 0;
  }

  .sidebar__tag-buttons .btn {
    padding: .5rem 1rem;
    font-size: 1.25rem;
  }

  .sidebar__tag-buttons .btn--tag-primary {
    margin-bottom: unset;
  }
}

.footer__container {
  padding: 1.25rem;
}



.btn, .btn-md {
  cursor: pointer;
  background-color: var(--button-text-color);
  color: var(--background-secondary-color);
  border: none;
  border-radius: .5rem;
  justify-content: center;
  align-items: center;
  width: max-content;
  min-height: 2.5rem;
  padding: .5rem 1rem;
  font-family: Coiny, Roboto, sans-serif;
  font-size: 1.25rem;
  line-height: 1;
  text-decoration: none;
  transition: background-color .2s, color .2s, box-shadow .2s;
  display: flex;
}

.btn:hover {
  box-shadow: 0 2px 4px #0000001a;
}

.btn:active {
  box-shadow: 0 1px 2px #0000001a;
}

.btn:disabled, .btn--disabled {
  cursor: not-allowed;
  box-shadow: none;
  opacity: .5;
}

.btn:disabled:hover, .btn--disabled:hover {
  box-shadow: none;
}

.btn--xl {
  border-radius: .75rem;
  padding: .75rem 1.5rem;
  font-size: 2rem;
}

.btn--lg {
  border-radius: .75rem;
  padding: .75rem 1.5rem;
  font-size: 1.5rem;
}

.btn--primary {
  background-color: var(--button-primary-color);
  color: var(--button-text-color);
}

.btn--primary:hover {
  background-color: var(--button-primary-hover-color);
  color: var(--button-text-color);
}

.btn--primary:active {
  background-color: var(--button-primary-active-color);
  color: var(--button-text-color);
}

.btn--primary.btn--disabled:hover, .btn--primary.btn--disabled:active, .btn--primary:disabled:hover, .btn--primary:disabled:active {
  background-color: var(--button-primary-color);
  color: var(--button-text-color);
}

.btn--secondary {
  background-color: var(--button-secondary-color);
  color: var(--button-text-color);
}

.btn--secondary:hover {
  background-color: var(--button-secondary-hover-color);
  color: var(--button-text-color);
}

.btn--secondary:active {
  background-color: var(--button-secondary-active-color);
  color: var(--button-text-color);
}

.btn--secondary.btn--disabled:hover, .btn--secondary.btn--disabled:active, .btn--secondary:disabled:hover, .btn--secondary:disabled:active {
  background-color: var(--button-secondary-color);
  color: var(--button-text-color);
}

.btn--tag-primary {
  background-color: var(--tag-button-primary-color);
  color: var(--button-text-color);
}

.btn--tag-primary:hover {
  background-color: var(--tag-button-primary-hover-color);
  color: var(--button-text-color);
}

.btn--tag-primary:active {
  background-color: var(--tag-button-primary-active-color);
  color: var(--button-text-color);
}

.btn--tag-primary.btn--disabled:hover, .btn--tag-primary.btn--disabled:active, .btn--tag-primary:disabled:hover, .btn--tag-primary:disabled:active {
  background-color: var(--tag-button-primary-color);
  color: var(--button-text-color);
}

.btn--tag-secondary {
  background-color: var(--tag-button-secondary-color);
  color: var(--button-text-color);
}

.btn--tag-secondary:hover {
  background-color: var(--tag-button-primary-color);
  color: var(--button-text-color);
}

.btn--tag-secondary:active {
  background-color: var(--tag-button-primary-active-color);
  color: var(--button-text-color);
}

.btn--tag-secondary.btn--disabled:hover, .btn--tag-secondary.btn--disabled:active, .btn--tag-secondary:disabled:hover, .btn--tag-secondary:disabled:active {
  background-color: var(--tag-button-secondary-color);
  color: var(--button-text-color);
}

.btn--active {
  background-color: var(--tag-button-primary-color);
  pointer-events: none;
}

.url {
  -webkit-text-decoration: unset;
  text-decoration: unset;
  font-family: Sono, Roboto, sans-serif;
  font-size: 1.25rem;
  transition: color .2s;
}

.url--inherit {
  font-family: inherit;
  font-size: inherit;
  color: inherit;
}

.url:link {
  cursor: pointer;
}

.url--primary {
  color: var(--url-text-primary-color);
}

.url--primary[href]:visited {
  color: var(--url-text-primary-visited-color);
}

.url--primary[href]:hover {
  color: var(--url-text-primary-hover-color);
}

.url--primary[href]:active {
  color: var(--url-text-primary-active-color);
}

.url--secondary {
  color: var(--url-text-secondary-color);
}

.url--secondary[href]:visited {
  color: var(--url-text-secondary-visited-color);
}

.url--secondary[href]:hover {
  color: var(--url-text-secondary-hover-color);
}

.url--secondary[href]:active {
  color: var(--url-text-secondary-active-color);
}

.form {
  letter-spacing: 0;
  width: 100%;
  height: auto;
  font-family: Sono, Roboto, sans-serif;
  line-height: 1.4;
}

.form__header, .form__content {
  margin-bottom: 1.75rem;
}

.form__title {
  font-family: inherit;
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 1.2;
}

.form__title--md {
  font-size: 1.5rem;
}

.form__title--lg {
  font-size: 1.75rem;
}

.form__title--xl {
  font-size: 2rem;
}

.form__item-group {
  width: 100%;
  margin-bottom: 1.5rem;
}

.form__label {
  color: var(--text-primary-color);
  margin-bottom: .5rem;
  font-size: 1.25rem;
  font-weight: 600;
  display: block;
}

.form__sub-label {
  opacity: .7;
  margin-left: .5rem;
  display: inline-block;
}

.form__sub-label--sm {
  font-size: .75rem;
}

.form__item {
  background-color: var(--button-secondary-color);
  color: var(--text-primary-color);
  border: 1px solid #0000;
  border-radius: .5rem;
  outline: none;
  width: 100%;
  padding: .75rem 1rem;
  font-family: inherit;
  font-size: 1.25rem;
  transition: all .2s;
}

textarea.form__item {
  resize: none;
}

input.input.form__item {
  cursor: pointer;
}

.form__item::placeholder {
  color: var(--text-primary-color);
  opacity: .5;
}

.form__item:focus {
  border: 1px solid var(--text-secondary-color);
}

.form__actions {
  justify-content: flex-end;
  align-items: center;
  gap: 1.25rem;
  width: 100%;
  display: flex;
}

.form__btn {
  font-family: inherit;
  font-weight: 600;
}



.card {
  background-color: var(--background-secondary-color);
  border-radius: .75rem;
  padding: 1.25rem 1.75rem;
}

.card--full {
  width: 100%;
}

@media (width <= 1023px) {
  .card {
    padding: 1.25rem;
  }
}

.error {
  letter-spacing: 0;
  color: var(--erorr-primary-color);
  width: 100%;
  margin-top: .5rem;
  font-family: Sono, Roboto, sans-serif;
  font-size: 1.25rem;
  line-height: 1.2;
}

.error__container {
  margin-bottom: 1rem;
}

.error__container:last-of-type {
  margin-bottom: unset;
}

.error--with-icon {
  justify-content: flex-start;
  align-items: flex-start;
  gap: .5rem;
  display: flex;
}

.error__icon {
  margin-top: 2px;
}

.error__text {
  font-family: inherit;
  display: inline-block;
}

.error--border, .error--border:focus {
  border: 1px solid var(--erorr-primary-color) !important;
}

.loader {
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 24rem;
  display: flex;
}

.loader__item {
  box-sizing: border-box;
  background: linear-gradient(0deg, #f638dc33 33%, #f638dc 100%);
  border-radius: 50%;
  width: 48px;
  height: 48px;
  animation: 1s linear infinite rotation;
  display: inline-block;
  position: relative;
}

.loader__item:after {
  content: "";
  box-sizing: border-box;
  background: var(--background-primary-color);
  border-radius: 50%;
  width: 40px;
  height: 40px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.loader__item--xl {
  width: 72px;
  height: 72px;
}

.loader__item--xl:after {
  width: 60px;
  height: 60px;
}

@keyframes rotation {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

.empty-content {
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 2rem;
  width: 100%;
  height: auto;
  padding-top: 4rem;
  display: flex;
}

.empty-content__text {
  letter-spacing: 0;
  color: var(--text-primary-color);
  text-align: center;
  width: 100%;
  font-family: Sono, Roboto, sans-serif;
  font-size: 1.75rem;
  line-height: 1.4;
}

.empty-content__icon {
  text-align: center;
  color: var(--text-primary-color);
  width: 100%;
  height: max-content;
  font-size: 1.5rem;
}

.modal__overlay {
  z-index: 100000;
  background: #000000b3;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
}

.modal__body {
  letter-spacing: 0;
  border: 1px solid var(--text-secondary-color);
  width: 80%;
  max-width: 600px;
  padding: 1rem 2rem;
  font-family: Sono, Roboto, sans-serif;
  font-size: 1.25rem;
  line-height: 1.4;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.modal__header, .modal__content, .modal__footer {
  padding: 1rem 0;
}

.modal__close {
  width: 100%;
}

@media (width <= 640px) {
  .modal__body {
    padding: .5rem 1.5rem;
  }
}

.hide {
  display: none !important;
}



.flatpickr-calendar, .flatpickr-calendar.multiMonth .flatpickr-days .dayContainer:nth-child(n+2) .flatpickr-day.inRange:nth-child(7n+1), .dayContainer + .dayContainer, .flatpickr-day.selected.startRange + .endRange:not(:nth-child(7n+1)), .flatpickr-day.startRange.startRange + .endRange:not(:nth-child(7n+1)), .flatpickr-day.endRange.startRange + .endRange:not(:nth-child(7n+1)), .flatpickr-day.inRange, .flatpickr-day.week.selected, .flatpickr-weekwrapper .flatpickr-weeks {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.flatpickr-calendar {
  background: var(--button-secondary-color);
  border: 1px solid var(--text-secondary-color);
  border-radius: .5rem;
  font-family: Sono, Roboto, sans-serif;
  font-size: 1rem;
  line-height: 1.4;
}

.flatpickr-calendar.arrowTop {
  margin-top: 12px;
}

.flatpickr-calendar.arrowBottom {
  margin-top: -12px;
}

.flatpickr-days {
  padding: .75rem 0;
}

.flatpickr-calendar.hasTime .flatpickr-time {
  border-top: 1px solid var(--text-secondary-color);
}

.flatpickr-calendar.arrowTop:before {
  border-bottom-color: var(--text-secondary-color);
}

.flatpickr-calendar.arrowTop:after {
  border-bottom-color: var(--button-secondary-color);
}

.flatpickr-calendar.arrowBottom:before {
  border-top-color: var(--text-secondary-color);
}

.flatpickr-calendar.arrowBottom:after {
  border-top-color: var(--button-secondary-color);
}

.flatpickr-months .flatpickr-month {
  background: var(--button-secondary-color);
  color: var(--text-primary-color);
  fill: var(--text-primary-color);
  border-radius: .5rem .5rem 0 0;
  height: 2.75rem;
}

.flatpickr-months .flatpickr-prev-month, .flatpickr-months .flatpickr-next-month {
  color: var(--text-primary-color);
  fill: var(--text-primary-color);
}

.flatpickr-months .flatpickr-prev-month:hover, .flatpickr-months .flatpickr-next-month:hover {
  color: var(--text-secondary-color);
}

.flatpickr-months .flatpickr-prev-month:hover svg, .flatpickr-months .flatpickr-next-month:hover svg {
  fill: var(--text-secondary-color);
}

.flatpickr-current-month .numInputWrapper span.arrowUp:after {
  border-bottom-color: var(--text-primary-color);
}

.flatpickr-current-month .numInputWrapper span.arrowDown:after {
  border-top-color: var(--text-primary-color);
}

.flatpickr-current-month input.cur-year[disabled], .flatpickr-current-month input.cur-year[disabled]:hover {
  color: var(--text-secondary-color);
  opacity: .4;
}

.flatpickr-months {
  grid-template-columns: 34px auto 34px;
  align-items: center;
  width: 100%;
  display: grid;
}

.flatpickr-current-month {
  position: unset;
  justify-content: center;
  align-items: center;
  gap: 16px;
  width: 100%;
  height: 100%;
  padding: 0;
  font-size: 1.25rem;
  display: flex;
}

.flatpickr-months .flatpickr-prev-month, .flatpickr-months .flatpickr-next-month {
  position: unset;
  transition: color fill 1s;
  justify-content: center;
  align-items: center;
  width: 34px;
  height: 34px;
  padding: 0;
  display: flex;
}

.flatpickr-months .flatpickr-prev-month.flatpickr-disabled, .flatpickr-months .flatpickr-next-month.flatpickr-disabled {
  visibility: hidden;
  display: block;
}

.flatpickr-months .flatpickr-prev-month svg path, .flatpickr-months .flatpickr-next-month svg path {
  transition: fill .2s;
}

.flatpickr-current-month .flatpickr-monthDropdown-months {
  background: var(--button-secondary-color);
  appearance: none;
  text-align: center;
  border: none;
  border-radius: .5rem;
  height: 2rem;
  padding: 0 4px;
}

.flatpickr-current-month .numInputWrapper {
  border-radius: .5rem;
  width: 6.5ch;
}

.flatpickr-current-month input.cur-year {
  border-radius: .5rem;
  height: 2rem;
  padding: 0 0 0 8px;
}

.numInputWrapper span {
  justify-content: center;
  align-items: center;
  width: 18px;
  padding: 0;
  display: flex;
}

.flatpickr-weekdays {
  border-bottom: 1px solid var(--text-secondary-color);
  border-top: 1px solid var(--text-secondary-color);
  height: 2rem;
}

span.flatpickr-weekday {
  background: var(--button-secondary-color);
  color: var(--text-primary-color);
}

.flatpickr-day {
  color: var(--text-primary-color);
}

.flatpickr-day.inRange, .flatpickr-day.prevMonthDay.inRange, .flatpickr-day.nextMonthDay.inRange, .flatpickr-day.today.inRange, .flatpickr-day.prevMonthDay.today.inRange, .flatpickr-day.nextMonthDay.today.inRange, .flatpickr-day:hover, .flatpickr-day.prevMonthDay:hover, .flatpickr-day.nextMonthDay:hover, .flatpickr-day:focus, .flatpickr-day.prevMonthDay:focus, .flatpickr-day.nextMonthDay:focus {
  background: var(--button-primary-hover-color);
  border-color: var(--button-primary-hover-color);
}

.flatpickr-day:hover, .flatpickr-day.nextMonthDay:hover {
  color: var(--text-primary-color);
}

.flatpickr-day.today {
  border-color: var(--text-secondary-color);
}

.flatpickr-day.today:hover, .flatpickr-day.today:focus {
  border-color: var(--button-primary-hover-color);
  background: var(--button-primary-hover-color);
  color: var(--text-primary-color);
}

.flatpickr-day.selected, .flatpickr-day.startRange, .flatpickr-day.endRange, .flatpickr-day.selected.inRange, .flatpickr-day.startRange.inRange, .flatpickr-day.endRange.inRange, .flatpickr-day.selected:focus, .flatpickr-day.startRange:focus, .flatpickr-day.endRange:focus, .flatpickr-day.selected:hover, .flatpickr-day.startRange:hover, .flatpickr-day.endRange:hover, .flatpickr-day.selected.prevMonthDay, .flatpickr-day.startRange.prevMonthDay, .flatpickr-day.endRange.prevMonthDay, .flatpickr-day.selected.nextMonthDay, .flatpickr-day.startRange.nextMonthDay, .flatpickr-day.endRange.nextMonthDay {
  background: var(--button-primary-color);
  color: var(--text-primary-color);
  border-color: var(--button-primary-color);
}

.flatpickr-day.flatpickr-disabled, .flatpickr-day.flatpickr-disabled:hover, .flatpickr-day.prevMonthDay, .flatpickr-day.nextMonthDay, .flatpickr-day.notAllowed, .flatpickr-day.notAllowed.prevMonthDay, .flatpickr-day.notAllowed.nextMonthDay {
  color: var(--text-secondary-color);
}

.flatpickr-day.flatpickr-disabled, .flatpickr-day.flatpickr-disabled:hover {
  color: var(--text-secondary-color);
  opacity: .3;
}

.flatpickr-weekwrapper span.flatpickr-day, .flatpickr-weekwrapper span.flatpickr-day:hover {
  color: var(--text-secondary-color);
}

.flatpickr-time .numInputWrapper span.arrowUp:after {
  border-bottom-color: var(--text-primary-color);
}

.flatpickr-time .numInputWrapper span.arrowDown:after {
  border-top-color: var(--text-primary-color);
}

.flatpickr-time input, .flatpickr-time .flatpickr-time-separator, .flatpickr-time .flatpickr-am-pm {
  color: var(--text-primary-color);
}

.flatpickr-time input:hover, .flatpickr-time .flatpickr-am-pm:hover, .flatpickr-time input:focus, .flatpickr-time .flatpickr-am-pm:focus {
  background: var(--button-secondary-color);
}

.flatpickr-calendar.animate.open {
  animation: none;
}

.flatpickr-calendar.animate.open.arrowTop {
  animation: .3s cubic-bezier(.23, 1, .32, 1) fpFadeInUp;
}

.flatpickr-calendar.animate.open.arrowBottom {
  animation: .3s cubic-bezier(.23, 1, .32, 1) fpFadeInDown;
}

@media (width <= 767px) {
  .flatpickr-calendar.animate.open.arrowTop, .flatpickr-calendar.animate.open.arrowBottom {
    animation: none;
  }
}

@keyframes fpFadeInUp {
  from {
    opacity: 0;
    transform: translate3d(0, 20px, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.choices {
  margin-bottom: unset;
}

.choices__inner {
  background-color: var(--button-secondary-color);
  border: 1px solid #0000;
  border-radius: .5rem;
  padding: .75rem 1rem;
  font-family: Sono, Roboto, sans-serif;
  font-size: 1.25rem;
  line-height: 1.4;
  transition: border-color .2s;
}

.choices__list--single {
  padding: 0;
}

.choices__list--dropdown, .choices__list[aria-expanded] {
  background-color: var(--button-secondary-color);
  border: 1px solid #0000;
}

.choices[data-type*="select-one"] .choices__button {
  opacity: .5;
  background-image: url("xmark-solid.dee61e20.svg");
  background-size: .75rem;
  margin-right: 1.75rem;
  transition: opacity .2s;
}

.is-focused .choices__inner, .is-open .choices__inner, .is-open .choices__list--dropdown, .is-open .choices__list[aria-expanded] {
  border-color: var(--text-secondary-color);
}

.choices__list--dropdown .choices__item, .choices__list[aria-expanded] .choices__item {
  font-size: 1.25rem;
}

.choices__list--dropdown .choices__item--selectable.is-highlighted, .choices__list[aria-expanded] .choices__item--selectable.is-highlighted {
  background-color: var(--button-primary-color);
}

.choices__list--dropdown .choices__item, .choices__list[aria-expanded] .choices__item {
  border-bottom: 1px solid var(--text-secondary-color);
  border-right: 1px solid var(--text-secondary-color);
  padding: .5rem 1rem;
}

.choices__list--dropdown .choices__item:last-of-type, .choices__list[aria-expanded] .choices__item:last-of-type {
  border-bottom: unset;
  padding: .75rem 1rem;
}

.choices__list--dropdown, .choices__list[aria-expanded], .is-flipped.is-open .choices__inner, .is-open .choices__inner, .is-flipped .choices__list--dropdown, .is-flipped .choices__list[aria-expanded] {
  border-radius: .5rem;
}

.choices[data-type*="select-one"]:after {
  border-color: var(--text-secondary-color) transparent transparent;
}

.choices[data-type*="select-one"].is-open:after {
  border-color: transparent transparent var(--text-secondary-color);
}

.choices__item--disabled {
  opacity: 1;
  color: var(--text-secondary-color);
  display: none;
}

.choices__list.choices__list--dropdown {
  margin-top: 16px;
}

.is-flipped .choices__list--dropdown, .is-flipped .choices__list[aria-expanded] {
  margin-bottom: 16px;
}

.choices__list--dropdown .choices__list, .choices__list[aria-expanded] .choices__list {
  max-height: 220px;
}

@media (width <= 767px) {
  .choices__list--dropdown .choices__list, .choices__list[aria-expanded] .choices__list {
    max-height: 180px;
  }
}





:root {
  --animate-duration: 1s;
  --animate-delay: 1s;
  --animate-repeat: 1;
}

.animate__animated {
  animation-duration: 1s;
  -webkit-animation-duration: var(--animate-duration);
  animation-duration: var(--animate-duration);
  animation-fill-mode: both;
}

.animate__animated.animate__infinite {
  animation-iteration-count: infinite;
}

.animate__animated.animate__repeat-1 {
  animation-iteration-count: 1;
  -webkit-animation-iteration-count: var(--animate-repeat);
  animation-iteration-count: var(--animate-repeat);
}

.animate__animated.animate__repeat-2 {
  animation-iteration-count: 2;
  -webkit-animation-iteration-count: calc(var(--animate-repeat) * 2);
  animation-iteration-count: calc(var(--animate-repeat) * 2);
}

.animate__animated.animate__repeat-3 {
  animation-iteration-count: 3;
  -webkit-animation-iteration-count: calc(var(--animate-repeat) * 3);
  animation-iteration-count: calc(var(--animate-repeat) * 3);
}

.animate__animated.animate__delay-1s {
  animation-delay: 1s;
  -webkit-animation-delay: var(--animate-delay);
  animation-delay: var(--animate-delay);
}

.animate__animated.animate__delay-2s {
  animation-delay: 2s;
  -webkit-animation-delay: calc(var(--animate-delay) * 2);
  animation-delay: calc(var(--animate-delay) * 2);
}

.animate__animated.animate__delay-3s {
  animation-delay: 3s;
  -webkit-animation-delay: calc(var(--animate-delay) * 3);
  animation-delay: calc(var(--animate-delay) * 3);
}

.animate__animated.animate__delay-4s {
  animation-delay: 4s;
  -webkit-animation-delay: calc(var(--animate-delay) * 4);
  animation-delay: calc(var(--animate-delay) * 4);
}

.animate__animated.animate__delay-5s {
  animation-delay: 5s;
  -webkit-animation-delay: calc(var(--animate-delay) * 5);
  animation-delay: calc(var(--animate-delay) * 5);
}

.animate__animated.animate__faster {
  animation-duration: .5s;
  -webkit-animation-duration: calc(var(--animate-duration) / 2);
  animation-duration: calc(var(--animate-duration) / 2);
}

.animate__animated.animate__fast {
  animation-duration: .8s;
  -webkit-animation-duration: calc(var(--animate-duration) * .8);
  animation-duration: calc(var(--animate-duration) * .8);
}

.animate__animated.animate__slow {
  animation-duration: 2s;
  -webkit-animation-duration: calc(var(--animate-duration) * 2);
  animation-duration: calc(var(--animate-duration) * 2);
}

.animate__animated.animate__slower {
  animation-duration: 3s;
  -webkit-animation-duration: calc(var(--animate-duration) * 3);
  animation-duration: calc(var(--animate-duration) * 3);
}

@media print, (prefers-reduced-motion: reduce) {
  .animate__animated {
    transition-duration: 1ms !important;
    animation-duration: 1ms !important;
    animation-iteration-count: 1 !important;
  }

  .animate__animated[class*="Out"] {
    opacity: 0;
  }
}

@keyframes bounce {
  from, 20%, 53%, to {
    animation-timing-function: cubic-bezier(.215, .61, .355, 1);
    transform: translate3d(0, 0, 0);
  }

  40%, 43% {
    animation-timing-function: cubic-bezier(.755, .05, .855, .06);
    transform: translate3d(0, -30px, 0)scaleY(1.1);
  }

  70% {
    animation-timing-function: cubic-bezier(.755, .05, .855, .06);
    transform: translate3d(0, -15px, 0)scaleY(1.05);
  }

  80% {
    transition-timing-function: cubic-bezier(.215, .61, .355, 1);
    transform: translate3d(0, 0, 0)scaleY(.95);
  }

  90% {
    transform: translate3d(0, -4px, 0)scaleY(1.02);
  }
}

.animate__bounce {
  transform-origin: bottom;
  animation-name: bounce;
}

@keyframes flash {
  from, 50%, to {
    opacity: 1;
  }

  25%, 75% {
    opacity: 0;
  }
}

.animate__flash {
  animation-name: flash;
}

@keyframes pulse {
  from {
    transform: scale3d(1, 1, 1);
  }

  50% {
    transform: scale3d(1.05, 1.05, 1.05);
  }

  to {
    transform: scale3d(1, 1, 1);
  }
}

.animate__pulse {
  animation-name: pulse;
  animation-timing-function: ease-in-out;
}

@keyframes rubberBand {
  from {
    transform: scale3d(1, 1, 1);
  }

  30% {
    transform: scale3d(1.25, .75, 1);
  }

  40% {
    transform: scale3d(.75, 1.25, 1);
  }

  50% {
    transform: scale3d(1.15, .85, 1);
  }

  65% {
    transform: scale3d(.95, 1.05, 1);
  }

  75% {
    transform: scale3d(1.05, .95, 1);
  }

  to {
    transform: scale3d(1, 1, 1);
  }
}

.animate__rubberBand {
  animation-name: rubberBand;
}

@keyframes shakeX {
  from, to {
    transform: translate3d(0, 0, 0);
  }

  10%, 30%, 50%, 70%, 90% {
    transform: translate3d(-10px, 0, 0);
  }

  20%, 40%, 60%, 80% {
    transform: translate3d(10px, 0, 0);
  }
}

.animate__shakeX {
  animation-name: shakeX;
}

@keyframes shakeY {
  from, to {
    transform: translate3d(0, 0, 0);
  }

  10%, 30%, 50%, 70%, 90% {
    transform: translate3d(0, -10px, 0);
  }

  20%, 40%, 60%, 80% {
    transform: translate3d(0, 10px, 0);
  }
}

.animate__shakeY {
  animation-name: shakeY;
}

@keyframes headShake {
  0% {
    transform: translateX(0);
  }

  6.5% {
    transform: translateX(-6px)rotateY(-9deg);
  }

  18.5% {
    transform: translateX(5px)rotateY(7deg);
  }

  31.5% {
    transform: translateX(-3px)rotateY(-5deg);
  }

  43.5% {
    transform: translateX(2px)rotateY(3deg);
  }

  50% {
    transform: translateX(0);
  }
}

.animate__headShake {
  animation-name: headShake;
  animation-timing-function: ease-in-out;
}

@keyframes swing {
  20% {
    transform: rotate3d(0, 0, 1, 15deg);
  }

  40% {
    transform: rotate3d(0, 0, 1, -10deg);
  }

  60% {
    transform: rotate3d(0, 0, 1, 5deg);
  }

  80% {
    transform: rotate3d(0, 0, 1, -5deg);
  }

  to {
    transform: rotate3d(0, 0, 1, 0);
  }
}

.animate__swing {
  transform-origin: top;
  animation-name: swing;
}

@keyframes tada {
  from {
    transform: scale3d(1, 1, 1);
  }

  10%, 20% {
    transform: scale3d(.9, .9, .9)rotate3d(0, 0, 1, -3deg);
  }

  30%, 50%, 70%, 90% {
    transform: scale3d(1.1, 1.1, 1.1)rotate3d(0, 0, 1, 3deg);
  }

  40%, 60%, 80% {
    transform: scale3d(1.1, 1.1, 1.1)rotate3d(0, 0, 1, -3deg);
  }

  to {
    transform: scale3d(1, 1, 1);
  }
}

.animate__tada {
  animation-name: tada;
}

@keyframes wobble {
  from {
    transform: translate3d(0, 0, 0);
  }

  15% {
    transform: translate3d(-25%, 0, 0)rotate3d(0, 0, 1, -5deg);
  }

  30% {
    transform: translate3d(20%, 0, 0)rotate3d(0, 0, 1, 3deg);
  }

  45% {
    transform: translate3d(-15%, 0, 0)rotate3d(0, 0, 1, -3deg);
  }

  60% {
    transform: translate3d(10%, 0, 0)rotate3d(0, 0, 1, 2deg);
  }

  75% {
    transform: translate3d(-5%, 0, 0)rotate3d(0, 0, 1, -1deg);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

.animate__wobble {
  animation-name: wobble;
}

@keyframes jello {
  from, 11.1%, to {
    transform: translate3d(0, 0, 0);
  }

  22.2% {
    transform: skewX(-12.5deg)skewY(-12.5deg);
  }

  33.3% {
    transform: skewX(6.25deg)skewY(6.25deg);
  }

  44.4% {
    transform: skewX(-3.125deg)skewY(-3.125deg);
  }

  55.5% {
    transform: skewX(1.5625deg)skewY(1.5625deg);
  }

  66.6% {
    transform: skewX(-.78125deg)skewY(-.78125deg);
  }

  77.7% {
    transform: skewX(.390625deg)skewY(.390625deg);
  }

  88.8% {
    transform: skewX(-.195313deg)skewY(-.195313deg);
  }
}

.animate__jello {
  transform-origin: center;
  animation-name: jello;
}

@keyframes heartBeat {
  0% {
    transform: scale(1);
  }

  14% {
    transform: scale(1.3);
  }

  28% {
    transform: scale(1);
  }

  42% {
    transform: scale(1.3);
  }

  70% {
    transform: scale(1);
  }
}

.animate__heartBeat {
  animation-name: heartBeat;
  animation-duration: 1.3s;
  -webkit-animation-duration: calc(var(--animate-duration) * 1.3);
  animation-duration: calc(var(--animate-duration) * 1.3);
  animation-timing-function: ease-in-out;
}

@keyframes backInDown {
  0% {
    opacity: .7;
    transform: translateY(-1200px)scale(.7);
  }

  80% {
    opacity: .7;
    transform: translateY(0)scale(.7);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.animate__backInDown {
  animation-name: backInDown;
}

@keyframes backInLeft {
  0% {
    opacity: .7;
    transform: translateX(-2000px)scale(.7);
  }

  80% {
    opacity: .7;
    transform: translateX(0)scale(.7);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.animate__backInLeft {
  animation-name: backInLeft;
}

@keyframes backInRight {
  0% {
    opacity: .7;
    transform: translateX(2000px)scale(.7);
  }

  80% {
    opacity: .7;
    transform: translateX(0)scale(.7);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.animate__backInRight {
  animation-name: backInRight;
}

@keyframes backInUp {
  0% {
    opacity: .7;
    transform: translateY(1200px)scale(.7);
  }

  80% {
    opacity: .7;
    transform: translateY(0)scale(.7);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.animate__backInUp {
  animation-name: backInUp;
}

@keyframes backOutDown {
  0% {
    opacity: 1;
    transform: scale(1);
  }

  20% {
    opacity: .7;
    transform: translateY(0)scale(.7);
  }

  100% {
    opacity: .7;
    transform: translateY(700px)scale(.7);
  }
}

.animate__backOutDown {
  animation-name: backOutDown;
}

@keyframes backOutLeft {
  0% {
    opacity: 1;
    transform: scale(1);
  }

  20% {
    opacity: .7;
    transform: translateX(0)scale(.7);
  }

  100% {
    opacity: .7;
    transform: translateX(-2000px)scale(.7);
  }
}

.animate__backOutLeft {
  animation-name: backOutLeft;
}

@keyframes backOutRight {
  0% {
    opacity: 1;
    transform: scale(1);
  }

  20% {
    opacity: .7;
    transform: translateX(0)scale(.7);
  }

  100% {
    opacity: .7;
    transform: translateX(2000px)scale(.7);
  }
}

.animate__backOutRight {
  animation-name: backOutRight;
}

@keyframes backOutUp {
  0% {
    opacity: 1;
    transform: scale(1);
  }

  20% {
    opacity: .7;
    transform: translateY(0)scale(.7);
  }

  100% {
    opacity: .7;
    transform: translateY(-700px)scale(.7);
  }
}

.animate__backOutUp {
  animation-name: backOutUp;
}

@keyframes bounceIn {
  from, 20%, 40%, 60%, 80%, to {
    animation-timing-function: cubic-bezier(.215, .61, .355, 1);
  }

  0% {
    opacity: 0;
    transform: scale3d(.3, .3, .3);
  }

  20% {
    transform: scale3d(1.1, 1.1, 1.1);
  }

  40% {
    transform: scale3d(.9, .9, .9);
  }

  60% {
    opacity: 1;
    transform: scale3d(1.03, 1.03, 1.03);
  }

  80% {
    transform: scale3d(.97, .97, .97);
  }

  to {
    opacity: 1;
    transform: scale3d(1, 1, 1);
  }
}

.animate__bounceIn {
  animation-duration: .75s;
  -webkit-animation-duration: calc(var(--animate-duration) * .75);
  animation-duration: calc(var(--animate-duration) * .75);
  animation-name: bounceIn;
}

@keyframes bounceInDown {
  from, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(.215, .61, .355, 1);
  }

  0% {
    opacity: 0;
    transform: translate3d(0, -3000px, 0)scaleY(3);
  }

  60% {
    opacity: 1;
    transform: translate3d(0, 25px, 0)scaleY(.9);
  }

  75% {
    transform: translate3d(0, -10px, 0)scaleY(.95);
  }

  90% {
    transform: translate3d(0, 5px, 0)scaleY(.985);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

.animate__bounceInDown {
  animation-name: bounceInDown;
}

@keyframes bounceInLeft {
  from, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(.215, .61, .355, 1);
  }

  0% {
    opacity: 0;
    transform: translate3d(-3000px, 0, 0)scaleX(3);
  }

  60% {
    opacity: 1;
    transform: translate3d(25px, 0, 0)scaleX(1);
  }

  75% {
    transform: translate3d(-10px, 0, 0)scaleX(.98);
  }

  90% {
    transform: translate3d(5px, 0, 0)scaleX(.995);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

.animate__bounceInLeft {
  animation-name: bounceInLeft;
}

@keyframes bounceInRight {
  from, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(.215, .61, .355, 1);
  }

  from {
    opacity: 0;
    transform: translate3d(3000px, 0, 0)scaleX(3);
  }

  60% {
    opacity: 1;
    transform: translate3d(-25px, 0, 0)scaleX(1);
  }

  75% {
    transform: translate3d(10px, 0, 0)scaleX(.98);
  }

  90% {
    transform: translate3d(-5px, 0, 0)scaleX(.995);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

.animate__bounceInRight {
  animation-name: bounceInRight;
}

@keyframes bounceInUp {
  from, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(.215, .61, .355, 1);
  }

  from {
    opacity: 0;
    transform: translate3d(0, 3000px, 0)scaleY(5);
  }

  60% {
    opacity: 1;
    transform: translate3d(0, -20px, 0)scaleY(.9);
  }

  75% {
    transform: translate3d(0, 10px, 0)scaleY(.95);
  }

  90% {
    transform: translate3d(0, -5px, 0)scaleY(.985);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

.animate__bounceInUp {
  animation-name: bounceInUp;
}

@keyframes bounceOut {
  20% {
    transform: scale3d(.9, .9, .9);
  }

  50%, 55% {
    opacity: 1;
    transform: scale3d(1.1, 1.1, 1.1);
  }

  to {
    opacity: 0;
    transform: scale3d(.3, .3, .3);
  }
}

.animate__bounceOut {
  animation-duration: .75s;
  -webkit-animation-duration: calc(var(--animate-duration) * .75);
  animation-duration: calc(var(--animate-duration) * .75);
  animation-name: bounceOut;
}

@keyframes bounceOutDown {
  20% {
    transform: translate3d(0, 10px, 0)scaleY(.985);
  }

  40%, 45% {
    opacity: 1;
    transform: translate3d(0, -20px, 0)scaleY(.9);
  }

  to {
    opacity: 0;
    transform: translate3d(0, 2000px, 0)scaleY(3);
  }
}

.animate__bounceOutDown {
  animation-name: bounceOutDown;
}

@keyframes bounceOutLeft {
  20% {
    opacity: 1;
    transform: translate3d(20px, 0, 0)scaleX(.9);
  }

  to {
    opacity: 0;
    transform: translate3d(-2000px, 0, 0)scaleX(2);
  }
}

.animate__bounceOutLeft {
  animation-name: bounceOutLeft;
}

@keyframes bounceOutRight {
  20% {
    opacity: 1;
    transform: translate3d(-20px, 0, 0)scaleX(.9);
  }

  to {
    opacity: 0;
    transform: translate3d(2000px, 0, 0)scaleX(2);
  }
}

.animate__bounceOutRight {
  animation-name: bounceOutRight;
}

@keyframes bounceOutUp {
  20% {
    transform: translate3d(0, -10px, 0)scaleY(.985);
  }

  40%, 45% {
    opacity: 1;
    transform: translate3d(0, 20px, 0)scaleY(.9);
  }

  to {
    opacity: 0;
    transform: translate3d(0, -2000px, 0)scaleY(3);
  }
}

.animate__bounceOutUp {
  animation-name: bounceOutUp;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.animate__fadeIn {
  animation-name: fadeIn;
}

@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.animate__fadeInDown {
  animation-name: fadeInDown;
}

@keyframes fadeInDownBig {
  from {
    opacity: 0;
    transform: translate3d(0, -2000px, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.animate__fadeInDownBig {
  animation-name: fadeInDownBig;
}

@keyframes fadeInLeft {
  from {
    opacity: 0;
    transform: translate3d(-100%, 0, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.animate__fadeInLeft {
  animation-name: fadeInLeft;
}

@keyframes fadeInLeftBig {
  from {
    opacity: 0;
    transform: translate3d(-2000px, 0, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.animate__fadeInLeftBig {
  animation-name: fadeInLeftBig;
}

@keyframes fadeInRight {
  from {
    opacity: 0;
    transform: translate3d(100%, 0, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.animate__fadeInRight {
  animation-name: fadeInRight;
}

@keyframes fadeInRightBig {
  from {
    opacity: 0;
    transform: translate3d(2000px, 0, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.animate__fadeInRightBig {
  animation-name: fadeInRightBig;
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.animate__fadeInUp {
  animation-name: fadeInUp;
}

@keyframes fadeInUpBig {
  from {
    opacity: 0;
    transform: translate3d(0, 2000px, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.animate__fadeInUpBig {
  animation-name: fadeInUpBig;
}

@keyframes fadeInTopLeft {
  from {
    opacity: 0;
    transform: translate3d(-100%, -100%, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.animate__fadeInTopLeft {
  animation-name: fadeInTopLeft;
}

@keyframes fadeInTopRight {
  from {
    opacity: 0;
    transform: translate3d(100%, -100%, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.animate__fadeInTopRight {
  animation-name: fadeInTopRight;
}

@keyframes fadeInBottomLeft {
  from {
    opacity: 0;
    transform: translate3d(-100%, 100%, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.animate__fadeInBottomLeft {
  animation-name: fadeInBottomLeft;
}

@keyframes fadeInBottomRight {
  from {
    opacity: 0;
    transform: translate3d(100%, 100%, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.animate__fadeInBottomRight {
  animation-name: fadeInBottomRight;
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

.animate__fadeOut {
  animation-name: fadeOut;
}

@keyframes fadeOutDown {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }
}

.animate__fadeOutDown {
  animation-name: fadeOutDown;
}

@keyframes fadeOutDownBig {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate3d(0, 2000px, 0);
  }
}

.animate__fadeOutDownBig {
  animation-name: fadeOutDownBig;
}

@keyframes fadeOutLeft {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate3d(-100%, 0, 0);
  }
}

.animate__fadeOutLeft {
  animation-name: fadeOutLeft;
}

@keyframes fadeOutLeftBig {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate3d(-2000px, 0, 0);
  }
}

.animate__fadeOutLeftBig {
  animation-name: fadeOutLeftBig;
}

@keyframes fadeOutRight {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate3d(100%, 0, 0);
  }
}

.animate__fadeOutRight {
  animation-name: fadeOutRight;
}

@keyframes fadeOutRightBig {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate3d(2000px, 0, 0);
  }
}

.animate__fadeOutRightBig {
  animation-name: fadeOutRightBig;
}

@keyframes fadeOutUp {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }
}

.animate__fadeOutUp {
  animation-name: fadeOutUp;
}

@keyframes fadeOutUpBig {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate3d(0, -2000px, 0);
  }
}

.animate__fadeOutUpBig {
  animation-name: fadeOutUpBig;
}

@keyframes fadeOutTopLeft {
  from {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }

  to {
    opacity: 0;
    transform: translate3d(-100%, -100%, 0);
  }
}

.animate__fadeOutTopLeft {
  animation-name: fadeOutTopLeft;
}

@keyframes fadeOutTopRight {
  from {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }

  to {
    opacity: 0;
    transform: translate3d(100%, -100%, 0);
  }
}

.animate__fadeOutTopRight {
  animation-name: fadeOutTopRight;
}

@keyframes fadeOutBottomRight {
  from {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }

  to {
    opacity: 0;
    transform: translate3d(100%, 100%, 0);
  }
}

.animate__fadeOutBottomRight {
  animation-name: fadeOutBottomRight;
}

@keyframes fadeOutBottomLeft {
  from {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }

  to {
    opacity: 0;
    transform: translate3d(-100%, 100%, 0);
  }
}

.animate__fadeOutBottomLeft {
  animation-name: fadeOutBottomLeft;
}

@keyframes flip {
  from {
    animation-timing-function: ease-out;
    transform: perspective(400px)scale3d(1, 1, 1)translate3d(0, 0, 0)rotate3d(0, 1, 0, -360deg);
  }

  40% {
    animation-timing-function: ease-out;
    transform: perspective(400px)scale3d(1, 1, 1)translate3d(0, 0, 150px)rotate3d(0, 1, 0, -190deg);
  }

  50% {
    animation-timing-function: ease-in;
    transform: perspective(400px)scale3d(1, 1, 1)translate3d(0, 0, 150px)rotate3d(0, 1, 0, -170deg);
  }

  80% {
    animation-timing-function: ease-in;
    transform: perspective(400px)scale3d(.95, .95, .95)translate3d(0, 0, 0)rotate3d(0, 1, 0, 0);
  }

  to {
    animation-timing-function: ease-in;
    transform: perspective(400px)scale3d(1, 1, 1)translate3d(0, 0, 0)rotate3d(0, 1, 0, 0);
  }
}

.animate__animated.animate__flip {
  backface-visibility: visible;
  animation-name: flip;
}

@keyframes flipInX {
  from {
    opacity: 0;
    animation-timing-function: ease-in;
    transform: perspective(400px)rotate3d(1, 0, 0, 90deg);
  }

  40% {
    animation-timing-function: ease-in;
    transform: perspective(400px)rotate3d(1, 0, 0, -20deg);
  }

  60% {
    opacity: 1;
    transform: perspective(400px)rotate3d(1, 0, 0, 10deg);
  }

  80% {
    transform: perspective(400px)rotate3d(1, 0, 0, -5deg);
  }

  to {
    transform: perspective(400px);
  }
}

.animate__flipInX {
  animation-name: flipInX;
  backface-visibility: visible !important;
}

@keyframes flipInY {
  from {
    opacity: 0;
    animation-timing-function: ease-in;
    transform: perspective(400px)rotate3d(0, 1, 0, 90deg);
  }

  40% {
    animation-timing-function: ease-in;
    transform: perspective(400px)rotate3d(0, 1, 0, -20deg);
  }

  60% {
    opacity: 1;
    transform: perspective(400px)rotate3d(0, 1, 0, 10deg);
  }

  80% {
    transform: perspective(400px)rotate3d(0, 1, 0, -5deg);
  }

  to {
    transform: perspective(400px);
  }
}

.animate__flipInY {
  animation-name: flipInY;
  backface-visibility: visible !important;
}

@keyframes flipOutX {
  from {
    transform: perspective(400px);
  }

  30% {
    opacity: 1;
    transform: perspective(400px)rotate3d(1, 0, 0, -20deg);
  }

  to {
    opacity: 0;
    transform: perspective(400px)rotate3d(1, 0, 0, 90deg);
  }
}

.animate__flipOutX {
  animation-duration: .75s;
  -webkit-animation-duration: calc(var(--animate-duration) * .75);
  animation-duration: calc(var(--animate-duration) * .75);
  animation-name: flipOutX;
  backface-visibility: visible !important;
}

@keyframes flipOutY {
  from {
    transform: perspective(400px);
  }

  30% {
    opacity: 1;
    transform: perspective(400px)rotate3d(0, 1, 0, -15deg);
  }

  to {
    opacity: 0;
    transform: perspective(400px)rotate3d(0, 1, 0, 90deg);
  }
}

.animate__flipOutY {
  animation-duration: .75s;
  -webkit-animation-duration: calc(var(--animate-duration) * .75);
  animation-duration: calc(var(--animate-duration) * .75);
  animation-name: flipOutY;
  backface-visibility: visible !important;
}

@keyframes lightSpeedInRight {
  from {
    opacity: 0;
    transform: translate3d(100%, 0, 0)skewX(-30deg);
  }

  60% {
    opacity: 1;
    transform: skewX(20deg);
  }

  80% {
    transform: skewX(-5deg);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

.animate__lightSpeedInRight {
  animation-name: lightSpeedInRight;
  animation-timing-function: ease-out;
}

@keyframes lightSpeedInLeft {
  from {
    opacity: 0;
    transform: translate3d(-100%, 0, 0)skewX(30deg);
  }

  60% {
    opacity: 1;
    transform: skewX(-20deg);
  }

  80% {
    transform: skewX(5deg);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

.animate__lightSpeedInLeft {
  animation-name: lightSpeedInLeft;
  animation-timing-function: ease-out;
}

@keyframes lightSpeedOutRight {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate3d(100%, 0, 0)skewX(30deg);
  }
}

.animate__lightSpeedOutRight {
  animation-name: lightSpeedOutRight;
  animation-timing-function: ease-in;
}

@keyframes lightSpeedOutLeft {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate3d(-100%, 0, 0)skewX(-30deg);
  }
}

.animate__lightSpeedOutLeft {
  animation-name: lightSpeedOutLeft;
  animation-timing-function: ease-in;
}

@keyframes rotateIn {
  from {
    opacity: 0;
    transform: rotate3d(0, 0, 1, -200deg);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.animate__rotateIn {
  transform-origin: center;
  animation-name: rotateIn;
}

@keyframes rotateInDownLeft {
  from {
    opacity: 0;
    transform: rotate3d(0, 0, 1, -45deg);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.animate__rotateInDownLeft {
  transform-origin: 0 100%;
  animation-name: rotateInDownLeft;
}

@keyframes rotateInDownRight {
  from {
    opacity: 0;
    transform: rotate3d(0, 0, 1, 45deg);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.animate__rotateInDownRight {
  transform-origin: 100% 100%;
  animation-name: rotateInDownRight;
}

@keyframes rotateInUpLeft {
  from {
    opacity: 0;
    transform: rotate3d(0, 0, 1, 45deg);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.animate__rotateInUpLeft {
  transform-origin: 0 100%;
  animation-name: rotateInUpLeft;
}

@keyframes rotateInUpRight {
  from {
    opacity: 0;
    transform: rotate3d(0, 0, 1, -90deg);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.animate__rotateInUpRight {
  transform-origin: 100% 100%;
  animation-name: rotateInUpRight;
}

@keyframes rotateOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: rotate3d(0, 0, 1, 200deg);
  }
}

.animate__rotateOut {
  transform-origin: center;
  animation-name: rotateOut;
}

@keyframes rotateOutDownLeft {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: rotate3d(0, 0, 1, 45deg);
  }
}

.animate__rotateOutDownLeft {
  transform-origin: 0 100%;
  animation-name: rotateOutDownLeft;
}

@keyframes rotateOutDownRight {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: rotate3d(0, 0, 1, -45deg);
  }
}

.animate__rotateOutDownRight {
  transform-origin: 100% 100%;
  animation-name: rotateOutDownRight;
}

@keyframes rotateOutUpLeft {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: rotate3d(0, 0, 1, -45deg);
  }
}

.animate__rotateOutUpLeft {
  transform-origin: 0 100%;
  animation-name: rotateOutUpLeft;
}

@keyframes rotateOutUpRight {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: rotate3d(0, 0, 1, 90deg);
  }
}

.animate__rotateOutUpRight {
  transform-origin: 100% 100%;
  animation-name: rotateOutUpRight;
}

@keyframes hinge {
  0% {
    animation-timing-function: ease-in-out;
  }

  20%, 60% {
    animation-timing-function: ease-in-out;
    transform: rotate3d(0, 0, 1, 80deg);
  }

  40%, 80% {
    opacity: 1;
    animation-timing-function: ease-in-out;
    transform: rotate3d(0, 0, 1, 60deg);
  }

  to {
    opacity: 0;
    transform: translate3d(0, 700px, 0);
  }
}

.animate__hinge {
  animation-duration: 2s;
  -webkit-animation-duration: calc(var(--animate-duration) * 2);
  animation-duration: calc(var(--animate-duration) * 2);
  transform-origin: 0 0;
  animation-name: hinge;
}

@keyframes jackInTheBox {
  from {
    opacity: 0;
    transform-origin: bottom;
    transform: scale(.1)rotate(30deg);
  }

  50% {
    transform: rotate(-10deg);
  }

  70% {
    transform: rotate(3deg);
  }

  to {
    opacity: 1;
    transform: scale(1);
  }
}

.animate__jackInTheBox {
  animation-name: jackInTheBox;
}

@keyframes rollIn {
  from {
    opacity: 0;
    transform: translate3d(-100%, 0, 0)rotate3d(0, 0, 1, -120deg);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.animate__rollIn {
  animation-name: rollIn;
}

@keyframes rollOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate3d(100%, 0, 0)rotate3d(0, 0, 1, 120deg);
  }
}

.animate__rollOut {
  animation-name: rollOut;
}

@keyframes zoomIn {
  from {
    opacity: 0;
    transform: scale3d(.3, .3, .3);
  }

  50% {
    opacity: 1;
  }
}

.animate__zoomIn {
  animation-name: zoomIn;
}

@keyframes zoomInDown {
  from {
    opacity: 0;
    animation-timing-function: cubic-bezier(.55, .055, .675, .19);
    transform: scale3d(.1, .1, .1)translate3d(0, -1000px, 0);
  }

  60% {
    opacity: 1;
    animation-timing-function: cubic-bezier(.175, .885, .32, 1);
    transform: scale3d(.475, .475, .475)translate3d(0, 60px, 0);
  }
}

.animate__zoomInDown {
  animation-name: zoomInDown;
}

@keyframes zoomInLeft {
  from {
    opacity: 0;
    animation-timing-function: cubic-bezier(.55, .055, .675, .19);
    transform: scale3d(.1, .1, .1)translate3d(-1000px, 0, 0);
  }

  60% {
    opacity: 1;
    animation-timing-function: cubic-bezier(.175, .885, .32, 1);
    transform: scale3d(.475, .475, .475)translate3d(10px, 0, 0);
  }
}

.animate__zoomInLeft {
  animation-name: zoomInLeft;
}

@keyframes zoomInRight {
  from {
    opacity: 0;
    animation-timing-function: cubic-bezier(.55, .055, .675, .19);
    transform: scale3d(.1, .1, .1)translate3d(1000px, 0, 0);
  }

  60% {
    opacity: 1;
    animation-timing-function: cubic-bezier(.175, .885, .32, 1);
    transform: scale3d(.475, .475, .475)translate3d(-10px, 0, 0);
  }
}

.animate__zoomInRight {
  animation-name: zoomInRight;
}

@keyframes zoomInUp {
  from {
    opacity: 0;
    animation-timing-function: cubic-bezier(.55, .055, .675, .19);
    transform: scale3d(.1, .1, .1)translate3d(0, 1000px, 0);
  }

  60% {
    opacity: 1;
    animation-timing-function: cubic-bezier(.175, .885, .32, 1);
    transform: scale3d(.475, .475, .475)translate3d(0, -60px, 0);
  }
}

.animate__zoomInUp {
  animation-name: zoomInUp;
}

@keyframes zoomOut {
  from {
    opacity: 1;
  }

  50% {
    opacity: 0;
    transform: scale3d(.3, .3, .3);
  }

  to {
    opacity: 0;
  }
}

.animate__zoomOut {
  animation-name: zoomOut;
}

@keyframes zoomOutDown {
  40% {
    opacity: 1;
    animation-timing-function: cubic-bezier(.55, .055, .675, .19);
    transform: scale3d(.475, .475, .475)translate3d(0, -60px, 0);
  }

  to {
    opacity: 0;
    animation-timing-function: cubic-bezier(.175, .885, .32, 1);
    transform: scale3d(.1, .1, .1)translate3d(0, 2000px, 0);
  }
}

.animate__zoomOutDown {
  transform-origin: bottom;
  animation-name: zoomOutDown;
}

@keyframes zoomOutLeft {
  40% {
    opacity: 1;
    transform: scale3d(.475, .475, .475)translate3d(42px, 0, 0);
  }

  to {
    opacity: 0;
    transform: scale(.1)translate3d(-2000px, 0, 0);
  }
}

.animate__zoomOutLeft {
  transform-origin: 0;
  animation-name: zoomOutLeft;
}

@keyframes zoomOutRight {
  40% {
    opacity: 1;
    transform: scale3d(.475, .475, .475)translate3d(-42px, 0, 0);
  }

  to {
    opacity: 0;
    transform: scale(.1)translate3d(2000px, 0, 0);
  }
}

.animate__zoomOutRight {
  transform-origin: 100%;
  animation-name: zoomOutRight;
}

@keyframes zoomOutUp {
  40% {
    opacity: 1;
    animation-timing-function: cubic-bezier(.55, .055, .675, .19);
    transform: scale3d(.475, .475, .475)translate3d(0, 60px, 0);
  }

  to {
    opacity: 0;
    animation-timing-function: cubic-bezier(.175, .885, .32, 1);
    transform: scale3d(.1, .1, .1)translate3d(0, -2000px, 0);
  }
}

.animate__zoomOutUp {
  transform-origin: bottom;
  animation-name: zoomOutUp;
}

@keyframes slideInDown {
  from {
    visibility: visible;
    transform: translate3d(0, -100%, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

.animate__slideInDown {
  animation-name: slideInDown;
}

@keyframes slideInLeft {
  from {
    visibility: visible;
    transform: translate3d(-100%, 0, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

.animate__slideInLeft {
  animation-name: slideInLeft;
}

@keyframes slideInRight {
  from {
    visibility: visible;
    transform: translate3d(100%, 0, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

.animate__slideInRight {
  animation-name: slideInRight;
}

@keyframes slideInUp {
  from {
    visibility: visible;
    transform: translate3d(0, 100%, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

.animate__slideInUp {
  animation-name: slideInUp;
}

@keyframes slideOutDown {
  from {
    transform: translate3d(0, 0, 0);
  }

  to {
    visibility: hidden;
    transform: translate3d(0, 100%, 0);
  }
}

.animate__slideOutDown {
  animation-name: slideOutDown;
}

@keyframes slideOutLeft {
  from {
    transform: translate3d(0, 0, 0);
  }

  to {
    visibility: hidden;
    transform: translate3d(-100%, 0, 0);
  }
}

.animate__slideOutLeft {
  animation-name: slideOutLeft;
}

@keyframes slideOutRight {
  from {
    transform: translate3d(0, 0, 0);
  }

  to {
    visibility: hidden;
    transform: translate3d(100%, 0, 0);
  }
}

.animate__slideOutRight {
  animation-name: slideOutRight;
}

@keyframes slideOutUp {
  from {
    transform: translate3d(0, 0, 0);
  }

  to {
    visibility: hidden;
    transform: translate3d(0, -100%, 0);
  }
}

.animate__slideOutUp {
  animation-name: slideOutUp;
}

/*# sourceMappingURL=index.527c1460.css.map */
